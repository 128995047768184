<template>
	<div id="hero" ref="target">
		<div id="hero_inner">
			
			<div class="hero_year" id="hero_year">
				<div v-for="(year, index) in years" :key="year.id" :data-index="index" class="year_box" :id="'year_box' + year.id">
					{{ year.value }}
				</div>
			</div>
			
			<h1 class="hero_words">
				<div v-for="(word, index) in words" :key="word.id" :data-index="index" class="hero_word">
					{{ $t('words[' + index + ']') }}
				</div>
			</h1>

		</div>
	</div>
</template>

<script>
import { onMounted, ref } from 'vue'
import gsap from 'gsap'

export default {
    name: 'Hero',
    setup(){

		const years = [
			{ value: '2', id: 1 },
			{ value: '0', id: 2 },
			{ value: '2', id: 3 },
			{ value: '1', id: 4 },
		]

        const words = [
			{ value: '', id: 1 },
			{ value: '', id: 2 },
			{ value: '', id: 3 },
			{ value: '', id: 4 },
			{ value: '', id: 5 },
		]

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.8 }
		);

		// INTERCEPT ANIMATION
		const inView = () => {

			// collect elements
			const hero_words = document.querySelectorAll('.hero_word');
			const year_box = document.querySelectorAll('.year_box');

			// loop words
			for(let i = 0; i <= (hero_words.length - 1); i++){
				gsap.to(hero_words[i], {
					y: 0,
					opacity: 1,
					duration: 1,
					delay: 0.3 + (0.1 * i )
				})
			}

			// loop year
			for(let i = 0; i <= (year_box.length - 1); i++){
				gsap.to(year_box[i], {
					duration: 0.7,
					opacity: 1,
					y: 0,
					scaleY: 1,
					delay: 0.5 + (0.1 * i)
				})
			}

			// container
			gsap.to('#hero_inner', {
				duration:1,
				scale:1.05,
				delay: 2,
				ease: 'power1.inOut'
			});

		}
		// END INTERCEPT

		onMounted(()=>{
			
			// SCROLL PARALLAX
			const hero = document.getElementById('hero');
			const year_box1 = document.getElementById('year_box1');
			const year_box2 = document.getElementById('year_box2');
			const year_box3 = document.getElementById('year_box3');
			const year_box4 = document.getElementById('year_box4');

			const handleHeroScroll = (evt) => {
				const hero_box = hero.getBoundingClientRect();
				const hero_height = hero.offsetHeight / 2 // only middle have graphics
				const hero_top = hero_box.top;

				const ratio = 200 / hero_height; // xxx total px to move up
				
				year_box1.style.marginTop = ((ratio * hero_top) * 0.25) + 'px'
				year_box2.style.marginTop = ((ratio * hero_top) * 0.5) + 'px'
				year_box3.style.marginTop = ((ratio * hero_top) * 0.75) + 'px'
				year_box4.style.marginTop = ((ratio * hero_top) * 1) + 'px'

			}
			document.addEventListener('scroll', handleHeroScroll)
			// END SCROLL PARALLAX

			// onMounted INTERCEPT
			observer.observe(target.value);

			// init gsap
			const hero_words = document.querySelectorAll('.hero_word');
			const year_box = document.querySelectorAll('.year_box');
		
			gsap.set(year_box, {opacity: 0, scaleY: 0.3, transformOrigin:"50% 0%" })
			gsap.set(hero_words, {opacity: 0, y: '+=60'})

		})
		return{ 
			target,
            years, words
		}
    }
}
</script>


<i18n>
{
	"en": {
		"words": [
			"Company",
			"Annual",
			"and",
			"Sustainability",
			"Report"
		]
  },
  "se": {
	  "words": [
			"Företaget",
			"Års-",
			"och",
			"Hållbarhetsredovisning",
			""
		]
  }
}
</i18n>