<template>
	<section class="section_numbers" ref="target">
		<div class="container">

			<div class="flex">
				<div class="flex-half numbers_intro flex-gutter-side">
					<h2>
						{{ $t('numberCounter_header') }}
					</h2>
					<p>
						{{ $t('numberCounter_text') }}
					</p>
				</div>
			</div>
			<!-- numbers -->
			<div class="flex align-start">

				<!-- <div class="flex flex-three-quarter"> -->
				
				
				<div class="grid grid_numbers flex-three-quarter">


					<div class="flex-third" v-for="(number, index) in numbers" :key="index">
						<div class="number_countainer" >
							<div class="number_counter"
								:data-start_value="number.start_value"
								:data-end_value="number.end_value"
								:data-separator_en="number.separator_en"
								:data-separator_se="number.separator_se"
								:data-sep_pos="number.sep_pos"
								:data-leading_zero="number.leading_zero"
								:class="{hide: doHide}"
							> 
								{{number.end_value}} 
							</div>
							<div class="number_counter_lang" :class="{show: doShow}">
								{{ $t('numberCounter[' + index + ']') }}
							</div>
							<div class="number_counter_text text-regular">
								{{ $t('numberCounterText[' + index + ']') }}
							</div>
						</div>
					</div>
				</div>

				<div class="flex-quarter flex-gutter-all">
					<!-- splash -->
					<div class="splash_container flex-gutter-all">
						<div class="splash number_splash">
							<div class="splash_bg">
								<div class="splash_content">
									<div class="splash_text">
										<div class="splash_text_inner">
											<span v-html="$t('numberCounter_splash')"></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<p class="numberCounter_usp text-regular">
						{{ $t('numberCounter_usp1') }}
					</p>
					<p class="numberCounter_usp text-regular">
						{{ $t('numberCounter_usp2') }}
					</p>
				</div>



			</div>
		</div>
	</section>
</template>

<script>
import { onMounted, onUpdated, ref } from 'vue'
import gsap from 'gsap'
import i18n from "@/i18n"

export default {
	name: 'CountNumbers',
	setup(){
		const doHide = ref(false)
		const doShow = ref(false)

		const numbers = [
			{id: 1, start_value: 48, end_value: 97, separator_en: '.', separator_se: ',', sep_pos: 1, leading_zero: ''},
			{id: 2, start_value: 25, end_value: 46, separator_en: '.', separator_se: ',', sep_pos: 0, leading_zero: '0'}, // add 0 to start
			{id: 3, start_value: 300, end_value: 410, separator_en: '.', separator_se: ':', sep_pos: 1, leading_zero: ''},
			{id: 4, start_value: 130, end_value: 210, separator_en: '.', separator_se: ',', sep_pos: 2, leading_zero: ''},
			{id: 5, start_value: 1000, end_value: 2591, separator_en: ',', separator_se: ' ', sep_pos: 1, leading_zero: ''},
			{id: 6, start_value: 25, end_value: 48, separator_en: '', separator_se: '', sep_pos: 0, leading_zero: ''}
		]

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.3 }
		);

		// INTERCEPT ANIMATION
		const inView = () => {

			const items = document.querySelectorAll(".number_counter");

			for(let i=0; i < items.length; i++){
				gsap.from(items[i], {
					textContent: items[i].dataset.start_value,
					duration: 5, // 4
					ease: 'circ.out',
					snap: { textContent: 1 },
					onComplete: function(){
						hideNumbers();
					},
					stagger: {
						each: 0.3,
						onUpdate: function() {
							this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent), 
								this.targets()[0].dataset.separator_en,
								this.targets()[0].dataset.separator_se,
								this.targets()[0].dataset.sep_pos,
								this.targets()[0].dataset.leading_zero,
							);
						},
					} // stagger
				}); // gsap from
			
			} // for loop

			function numberWithCommas(x, separator_en, separator_se, sep_pos, leading_zero) {

				let newString = ''
				
				if(i18n.global.locale == 'se'){
					newString = leading_zero + x.toString().slice(0, sep_pos) + separator_se + x.toString().slice(sep_pos);
				}else{
					newString = leading_zero + x.toString().slice(0, sep_pos) + separator_en + x.toString().slice(sep_pos);
				}
				
				return newString;
			}
			let i = 0;
			const hideNumbers = () =>{
				i++
				if(i == 6){
					doHide.value = true
					doShow.value = true
				}
			}

			// splash
			gsap.to('.number_splash', {duration: 1.8, delay: 0.0, scale: 1, ease: 'back.out'})

		} // end inView
		// END INTERCEPT

		onMounted(() => {

			// onMounted INTERCEPT
			observer.observe(target.value);

			// init gsap
			gsap.set('.number_splash', {scale:0})

		})
		return {
			numbers,
			doHide,
			doShow,
			target
		}
	}
}
</script>

<style>
	.hide{
		display:none;
	}
	.number_counter_lang{
		display:none;
	}
	.show{
		display:block;
	}
</style>