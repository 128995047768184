<template>
	<section class="section_textVideo" ref="target">
		<div class="container flex">
			
			<div class="flex-half flex-gutter-side">
				<h2 class="textVideo_header">
					{{ $t('TextVideo.header') }}
				</h2>
				<p class="textVideo_text">
					{{ $t('TextVideo.text') }}
				</p>
			</div>

			<div class="flex-half flex-gutter-side">

				<div class="video_player">
					
					<div @click="handleVideo" class="play_button" id="play_button">
						<div class="play_ring">
							<div class="play_icon"></div>
						</div>
						<img id="video_overlay_image" src="@/assets/video/video1_poster.jpg" alt="Video poster" />
					</div>

					<video id="video_comeback" controls type="video/mp4"></video>

				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { onMounted, ref } from 'vue'
import gsap from 'gsap'

export default {
	name: 'TextVideo',
	props:{

	},
	setup(){
		const video_url = '@/assets/video/video1_comeback.mp4'
		var video = document.getElementById('video');

		const handleVideo = () => {
			let video = document.getElementById('video_comeback');

			let vurl = require('@/assets/video/video1_comeback.mp4')
			let source = document.createElement('source');

			source.setAttribute('src', vurl );

			video.appendChild(source);
			video.play();
			
			let play_button = document.getElementById('play_button');
			play_button.style.display = 'none';
		}

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 1.0 }
		);

		// INTERCEPT ANIMATION
		const inView = () => {

			// header
			gsap.to('.textVideo_header', {
				duration: 1.5,
				opacity: 1,
				y: 0,
				ease: 'power2.out',
				delay: 0
			})
			// text
			gsap.to('.textVideo_text', {
				duration: 1.5,
				opacity: 1,
				y: 0,
				ease: 'power2.out',
				delay: 0.3
			})

		}
		// END INTERCEPT

		onMounted(()=>{

			// onMounted INTERCEPT
			observer.observe(target.value);

			// init gsap
			gsap.set(['.textVideo_header', '.textVideo_text'], {opacity: 0, y: '+=40'})

		})

		return {
			target,
			handleVideo
		}
	}
}
</script>