<template>
	<section class="section_video_hero" ref="target">
		
		<div class="container">
			<div class="video_player black_background">
				<video controls id="video_hero" type="video/mp4" poster="@/assets/video/poster.jpg">
				</video>

				<video autoplay playsinline muted loop @click="clickOverlay" poster="@/assets/video/poster_overlay.jpg" id="video_hero_overlay" src="@/assets/video/overlay_loop.mp4" type="video/mp4"
					:class="{ displayNone: hideElement }"
				>
				</video>

				<div @click="clickOverlay" id="video_hero_overlay_text" :class="{ displayNone: hideElement }">
					<div class="video_overlay_text_slogan">
						<h2 class="animated_header"><span :class="'header_bold' + randNum" class="header_bold">{{ $t('video_hero_bold_header') }}</span><span :class="'header_light' + randNum" class="header_light"> {{ $t('video_hero_light_header') }}</span></h2>
					</div>

					<div class="video_paragraph"><p>{{ $t('video_hero_text')}}</p></div>

					<div class="play_button_outer">
						<div class="play_button">
							<div class="play_container">
								<div class="play_ring">
									<div class="play_icon"></div>
								</div>
							</div>
						</div>
					</div>

					<div :class="'video_overlay_text_name' + randNum" class="video_overlay_text_name">{{ $t('video_hero_name') }}</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'

import {SplitText} from '@/assets/js/SplitText.min.js';

export default {
	name: "VideoHero",
	setup(){
		
		let hideElement = ref(0)
		const randNum = '_' + Math.floor(Math.random() * 1000000)

		const clickOverlay = () => {
		
			let video = document.getElementById('video_hero');

			let vurl = require('@/assets/video/large_phenomenal.mp4')
			let source = document.createElement('source');

			source.setAttribute('src', vurl );

			video.appendChild(source);
			video.play();

			const video_hero = document.getElementById('video_hero');
			const video_overlay_text = document.getElementById('video_overlay_text');

			hideElement.value = true;

			video_hero.play();
		}

			// INTERCEPT
			let onlyOnce = true;
			const target = ref();
			const observer = new IntersectionObserver(
				([entry]) => {
					if(entry.isIntersecting == true){

						if(onlyOnce == true){
							onlyOnce = false;
							inView();
						}
					}
				},
				{ threshold: 0.7 }
			);

		// INTERCEPT ANIMATION
		const inView = () => {

			// SPLIT TEXT
			// type:"words, lines, chars"
			// postion: "relative" = default || postion: "absolute" || obs absolute pajjar text flow vid minskning i size
			
			gsap.set(['.header_bold' + randNum,'.header_light' + randNum, '.video_overlay_text_name' + randNum], {opacity: 1})
			const header_bold = new SplitText('.header_bold' + randNum, {type:"words"})
			const header_light = new SplitText('.header_light' + randNum, {type:"words"})
			const name = new SplitText('.video_overlay_text_name' + randNum, {type:"words"})

			gsap.set(header_bold.words, {opacity: 0, x: '-50', y: '0', scale: 10})
			gsap.set(header_light.words, {opacity: 0, x: '-50', y: '0', scale: 1})
			gsap.set(name.words, {opacity: 0, x: '30', y: '0', scale: 1})
			gsap.set('.play_container', {opacity: 0, x: '0', y: '0', scale: 0})


			// header_bold loop
			for(let i = 0; i < header_bold.words.length; i++){
				gsap.to(header_bold.words[i], {
					duration: 0.3,
					opacity: 1,
					x: '0',
					y: 0,
					scale: 1,
					// ease: 'back.out(1.0)',
					ease: 'power0.out',
					delay: 0.3 + (i * 0.05	)
				})
			}
			
			// play button
			gsap.to('.play_container', {
				duration: 0.7,
				opacity: 1,
				x: '0',
				y: 0,
				scale: 1,
				ease: 'back.out(1.7)',
				// ease: 'power0.out',
				delay: 1.6,
				onComplete: function(){
					// const animation = gsap.timeline({repeat: -1, yoyo:true, delay:0.5 });
					// animation
					// .from('.play_button', { scale: 1.0, duration: 0.2 })
					// .to('.play_button', { scale: 1.1, duration: 0.2, delay:0.5 });
				}
			})

			// sub header loop
			for(let i = 0; i < header_light.words.length; i++){
				gsap.to(header_light.words[i], {
					duration: 0.7,
					opacity: 1,
					x: '0',
					y: 0,
					scale: 1,
					// ease: 'back.out(1.0)',
					ease: 'power2.out',
					delay: 0.9 + (i * 0.03	)
				})
			}
			
			// paragraph
			gsap.to('.video_paragraph', {
				duration: 0.7,
				opacity: 1,
				delay: 1.6
			})

			// name
			for(let i = 0; i < name.words.length; i++){
				gsap.to(name.words[i], {
					duration: 0.7,
					opacity: 1,
					x: 0,
					y: 0,
					scale: 1,
					// ease: 'back.out(1.0)',
					ease: 'power2.out',
					delay: 1.3 + (i * 0.03	)
				})
			}


			
			
		}
		// END INTERCEPT

		// onMounted INTERCEPT
		onMounted(()=>{
			observer.observe(target.value);
			gsap.set(['.header_bold' + randNum,'.header_light' + randNum, '.video_overlay_text_name' + randNum, '.play_container', '.video_paragraph'], {opacity: 0})			
		})
		return {
			target,
			clickOverlay,
			hideElement,
			randNum
		}
	}
}
</script>

<style>
	#trace{
		position: fixed;
		width:100%;
		height:40px;
		background:#fff;
		border:1 px solid #ccc;
		bottom:0;
		left:0;
		z-index: 999999;
	}

</style>