<template>
	<section ref="target" class="section_case" :class="'section_case' + randNum" :id="'section_case' + randNum">

		<div class="case_container">

			<div class="case_image_mobile_container">
				<picture class="">
					<img :src="require(`@/assets/img/${background}`)" alt="Svg Animation" class="svg_image">
				</picture>
			</div>

			<div class="flex">

				<!-- text right -->
				<div class="flex-half svg-col-image" v-if="alignTextLeft">
					<img :src="require(`@/assets/img/${background}`)" class="svg_image" alt="">
				</div>

				<div class="flex-half svg-col-content" v-if="alignTextLeft">
					<div class="content_right">
						<Accordion 
							:textClosed="textClosedArr" 
							:textOpen="textOpenArr" 
							:useAccordion="true"

						/>
					</div>
				</div>

				<!-- text left -->
				<div class="flex-half svg-col-content" v-if="!alignTextLeft">
					<div class="content_left">
						<Accordion 
							:textClosed="textClosedArr" 
							:textOpen="textOpenArr" 
							:useAccordion="true"
						/>
					</div>
				</div>

				<div class="flex-half svg-col-image" v-if="!alignTextLeft">
					<img :src="require(`@/assets/img/${background}`)" alt="Svg Animation" class="svg_image">
				</div>
			</div>
		</div>
	</section>
</template>
<script>

import { ref, onMounted } from 'vue'
import Accordion from '@/components/Accordion.vue'
import gsap from 'gsap'

export default {
	name: 'Svg',
	props:{
		textClosed: Array,
		textOpen: Array,
		alignTextLeft: Boolean,
		background: String
	},
	components: {
		Accordion
	},
	setup(props){
		const textClosedArr = props.textClosed
		const textOpenArr = props.textOpen
		const background = props.background
		const randNum = '_' + Math.floor(Math.random() * 1000000)

		// collect accordion elements for animation
		const ar = ['.section_case' + randNum + ' .closedAcc .case_indicator', '.section_case' + randNum + ' .closedAcc .case_header', '.section_case' + randNum + ' .closedAcc .case_text1', '.section_case' + randNum + ' .accordion_button' ]

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				// animate.value = entry.isIntersecting;
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{
				threshold: 0.6
			}
		);

		// INTERCEPT
		const inView = () => {

			// animate
			for(let i = 0; i < ar.length; i++){
				gsap.to(ar[i], {
					duration: 1.5,
					y: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: 0 + (i * 0.1)
				})
			}
		}
		// END INTERCEPT
		// MOUNTED
		onMounted(() => {
			
			// intercept 
			observer.observe(target.value);
			gsap.set(ar, {y: '100px', opacity:0 })


		})
		// END MOUNTED
		return{
			textClosedArr,
			textOpenArr,
			background,
			randNum,
			target
		}
	}
}
</script>

<style>


</style>