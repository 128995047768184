<template>
	<section class="section_video">
		<div class="full_width">
			<div class="video_player black_background" id="video_player_year">
				<video controls id="video_year" type="video/mp4">
				</video>

				<video autoplay muted loop @click="handleOverlay" id="video_overlay" src="@/assets/video/overlay_loop.mp4" type="video/mp4"
					:class="{ displayNone: hideElement }"
				>
				</video>

				<div @click="handleOverlay" id="video_overlay_text" :class="{ displayNone: hideElement }">
					<div class="video_overlay_text_inner">
						“{{ $t('video_overlay_header') }}”
					</div>
				</div>

			</div>
		</div>

		<div class="full_width black_background">	
			<div class="container">
				<div class="container_video_links flex-gutter-side">
					<a download class="video_links" href="@/assets/pdf/Chair_statement.pdf">{{ $t('video_link1_text') }} <span class="link_download_icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" style=""><path id="Path_1456" data-name="Path 1456" d="M19,12v7H5V12H3v7a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V12Zm-6,.67,2.59-2.58L17,11.5l-5,5-5-5,1.41-1.41L11,12.67V3h2Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span></a>
					<a download class="video_links" href="@/assets/pdf/CEO_statement.pdf">{{ $t('video_link2_text') }} <span class="link_download_icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" style=""><path id="Path_1456" data-name="Path 1456" d="M19,12v7H5V12H3v7a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V12Zm-6,.67,2.59-2.58L17,11.5l-5,5-5-5,1.41-1.41L11,12.67V3h2Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span></a>
				</div>
			</div>
		</div>

	</section>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {

	name: 'TextVideo',
	props:{

	},
	setup(){
		// const video_overlay_url = '@/assets/video/overlay_loop.mp4'
		// const video_year_url = '@/assets/video/video2_year2020.mp4'
		let hideElement = ref(0)

		const handleVideo = () => {
			// let video = document.getElementById('video_comeback');

			// video.controls = "controls"
			
		}

		const handleOverlay = () => {
		
			let video = document.getElementById('video_year');

			let vurl = require('@/assets/video/video2_year2020.mp4')
			let source = document.createElement('source');

			source.setAttribute('src', vurl );

			video.appendChild(source);
			video.play();

			const video_year = document.getElementById('video_year');
			const video_overlay_text = document.getElementById('video_overlay_text');

			hideElement.value = true;

			video_year.play();
		}

		return {
			handleVideo,
			handleOverlay,
			hideElement
		}
	}
}
</script>

<style>

</style>