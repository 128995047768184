<template>
	<footer>
		<div class="full_width black_background footer">

		<div class="fluid-container">
			<p class="copyright text-sm flex-gutter-side">{{ $t('footer.copyright') }}</p>
			
			<div class="flex flex-gutter-side">
				<div class="flex-quarter">
					<a class="text-sm footer_link" target="_blank" href="https://miltton.com/">Miltton.com <span class="link_arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" style="width: 0.75em; height: 0.75em;"><path id="Path_1458" data-name="Path 1458" d="M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span></a>
				</div>
				<div class="flex three-quarter align-children-right flex-stretch">
					<a class="text-sm footer_link footer_link_social footer_link_facebook" target="_blank" href="https://www.facebook.com/milttonagency">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.001 15.927" style="width: 1em; height: 1em;"><path d="M9746.085,905.926h0v-5.793h2.083l.27-2.619h-2.353v-1.544a.63.63,0,0,1,.653-.715h1.66v-2.546l-2.286-.012a2.923,2.923,0,0,0-2.51,1.1,3.465,3.465,0,0,0-.606,2.013v1.7h-1.466v2.624H9743v5.614a8,8,0,1,1,3.09.18Z" transform="translate(-9736.999 -889.999)" fill="currentColor"></path></svg>
							<span class="link_arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" style="width: 0.75em; height: 0.75em;"><path id="Path_1458" data-name="Path 1458" d="M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span>
						</div>
					</a>
					<a class="text-sm footer_link footer_link_social footer_link_twitter" target="_blank" href="https://twitter.com/miltton">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.232 14.005" style="width: 1.0625em; height: 0.875em;"><g id="Group_1212" data-name="Group 1212" transform="translate(-59.484 -246.219)"><path d="M76.716,247.877a7.056,7.056,0,0,1-2.031.556,3.548,3.548,0,0,0,1.555-1.956,7.073,7.073,0,0,1-2.245.858,3.538,3.538,0,0,0-6.025,3.225,10.034,10.034,0,0,1-7.287-3.694,3.539,3.539,0,0,0,1.094,4.72,3.52,3.52,0,0,1-1.6-.443c0,.015,0,.03,0,.045a3.537,3.537,0,0,0,2.836,3.466,3.541,3.541,0,0,1-1.6.061,3.538,3.538,0,0,0,3.3,2.455,7.146,7.146,0,0,1-5.234,1.464,10.054,10.054,0,0,0,15.478-8.471q0-.229-.011-.457a7.179,7.179,0,0,0,1.764-1.83Zm0,0" fill="currentColor"></path></g></svg>
							<span class="link_arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" style="width: 0.75em; height: 0.75em;"><path id="Path_1458" data-name="Path 1458" d="M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span>	
						</div>
					</a>
					<a class="text-sm footer_link footer_link_social footer_link_linkedin" target="_blank" href="https://www.linkedin.com/company/miltton/">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="15" viewBox="0 0 15.001 15" style="width: 0.9375em; height: 0.9375em;"><path d="M9751,905h-13a1,1,0,0,1-1-1V891a1,1,0,0,1,1-1h13a1,1,0,0,1,1,1v13A1,1,0,0,1,9751,905Zm-4.885-7.413c.761,0,1.162.54,1.162,1.561v3.7h2.3v-3.962c0-1.948-.987-3.111-2.641-3.111a2.285,2.285,0,0,0-2.073,1.143v-.981h-2.3c.026.529.01,4.911,0,6.771,0,.086,0,.136,0,.141h2.3v-3.86a1.581,1.581,0,0,1,.076-.558A1.27,1.27,0,0,1,9746.116,897.588ZM9739,895.94v6.907h2.3V895.94Zm1.18-3.529a1.183,1.183,0,1,0,1.185,1.182A1.182,1.182,0,0,0,9740.179,892.411Z" transform="translate(-9736.999 -890)" fill="currentColor"></path></svg>
							<span class="link_arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" style="width: 0.75em; height: 0.75em;"><path id="Path_1458" data-name="Path 1458" d="M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span>		
						</div>
					</a>
					<a class="text-sm footer_link footer_link_social footer_link_instagram" target="_blank" href="https://www.instagram.com/milttonagency/">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="14.001" height="14" viewBox="0 0 14.001 14" style="width: 0.875em; height: 0.875em;"><path d="M9740.859,904a3.859,3.859,0,0,1-3.859-3.859v-6.282a3.859,3.859,0,0,1,3.859-3.859h6.279a3.863,3.863,0,0,1,3.862,3.859v6.282a3.863,3.863,0,0,1-3.862,3.859Zm-2.729-10.124v6.248a2.746,2.746,0,0,0,2.746,2.746h6.247a2.748,2.748,0,0,0,2.746-2.746v-6.248a2.748,2.748,0,0,0-2.746-2.746h-6.247A2.746,2.746,0,0,0,9738.131,893.876ZM9740.24,897A3.759,3.759,0,1,1,9744,900.76,3.757,3.757,0,0,1,9740.24,897Zm1.307,0a2.454,2.454,0,1,0,2.452-2.455A2.451,2.451,0,0,0,9741.547,897Zm5.416-3.8a.842.842,0,1,1,.839.844A.841.841,0,0,1,9746.963,893.2Z" transform="translate(-9737 -890)" fill="currentColor"></path></svg>
							<span class="link_arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" style="width: 0.75em; height: 0.75em;"><path id="Path_1458" data-name="Path 1458" d="M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span>	
						</div>
					</a>
					<a class="text-sm footer_link footer_link_social footer_link_youtube" target="_blank" href="https://www.youtube.com/channel/UC2ADR969UzX4fxaDMvEKAGw">
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.854 12.497" style="width: 1.125em; height: 0.78125em;"><g id="Group_1211" data-name="Group 1211" transform="translate(-171.996 -135.147)"><g id="Group_1210" data-name="Group 1210" transform="translate(171.996 135.147)"><g id="Group_1209" data-name="Group 1209"><path id="Path_1459" data-name="Path 1459" d="M189.85,137.816a2.669,2.669,0,0,0-2.669-2.669H174.665A2.669,2.669,0,0,0,172,137.816v7.158a2.669,2.669,0,0,0,2.669,2.669h12.516a2.669,2.669,0,0,0,2.669-2.669Zm-10.712,6.6v-6.723l5.1,3.361Zm0,0" transform="translate(-171.996 -135.147)" fill="currentColor" fill-rule="evenodd"></path></g></g></g></svg>
							<span class="link_arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" style="width: 0.75em; height: 0.75em;"><path id="Path_1458" data-name="Path 1458" d="M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z" transform="translate(-3 -3)" fill="currentColor"></path></svg></span>
						</div>
					</a>
				</div>

			</div>
		</div>
		</div>
	</footer>
</template>

<script>
export default {

}
</script>

<style>

</style>