<template>

	<MainMenu />
	<router-view/>
	
</template>

<script>
document.title = "Miltton - H1 2021";

import MainMenu from '@/components/MainMenu.vue'

export default {

	name: 'App',
	components: {
		MainMenu
	},
	data(){
		return{

		}
	}
}
</script>