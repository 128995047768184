<template>
	<section class="section_slider" ref="target" :class="'section_slider_' + langObject">
		
		<div class="container">
		
		<div class="flex flex-third slider_intro flex-gutter-side">
			<div class="flex-half">
				<h2>{{ $t('slider.' + langObject + '.header') }}</h2>
				<p v-if="!multiSlideElements">{{ $t('slider.' + langObject + '.text') }}</p>
			</div>
		</div>
		</div>
		<div class="container">
		
		<!-- pagination -->
			<ul class="slider_nav" :class="{bullit_color : bullitColor}">
				<li @click="gotoSlide(index - 1)" v-for="index in numSlides" 
					:key="index" 
					:data-index="(index - 1)"
					class="slider_nav_item"
					:class="[{active : (index - 1) === counter}, 'slider_nav_item' + randNum + index]"
				>
					<span class="slider_nav_item_text" :class="'slider_nav_item_text' + randNum + index"
						v-html="$t('slider.' + langObject + '.slides[' + (index - 1) + '].timeline_indicator')"
					>
					</span>
				</li>
			</ul>
		</div>
		<div class="timeline_bar" :class="'timeline_bar' + randNum"></div>

		<!-- nav arrows -->
		<div @click="next" class="slider_next">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.174 33.001" class="transform rotate-180" style="width: 2em; height: 2em;"><defs></defs><g id="Group_1233" data-name="Group 1233" transform="translate(0 61.235)"><g id="Group_1206" data-name="Group 1206" transform="translate(0 -61.235)"><g id="Group_1204" data-name="Group 1204" transform="translate(0 16.5)"><path id="Path_1441" data-name="Path 1441" d="M0,0H31.167" fill="none" stroke="#b8babe" stroke-width="1"></path></g><g id="Group_1205" data-name="Group 1205" transform="translate(16.236 0.137)"><path id="Path_1442" data-name="Path 1442" d="M0-28,15.682-44.362,0-60.725" transform="translate(0 60.725)" fill="none" stroke="#b8babe" stroke-width="1"></path></g></g></g></svg>
		</div>

		<div @click="prev" class="slider_prev">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.174 33.001" class="transform rotate-180" style="width: 2em; height: 2em;"><defs></defs><g id="Group_1233" data-name="Group 1233" transform="translate(0 61.235)"><g id="Group_1206" data-name="Group 1206" transform="translate(0 -61.235)"><g id="Group_1204" data-name="Group 1204" transform="translate(0 16.5)"><path id="Path_1441" data-name="Path 1441" d="M0,0H31.167" fill="none" stroke="#b8babe" stroke-width="1"></path></g><g id="Group_1205" data-name="Group 1205" transform="translate(16.236 0.137)"><path id="Path_1442" data-name="Path 1442" d="M0-28,15.682-44.362,0-60.725" transform="translate(0 60.725)" fill="none" stroke="#b8babe" stroke-width="1"></path></g></g></g></svg>
		</div>
		
		<div class="slider_margin"></div>

		<!-- loop slides -->
		<div class="container">

			<transition-group
				class="slider flex"
				:id="'slider' + randNum"
				tag="div"
				name="slides"
				@leave="leaveSlide"
				@before-enter="beforeEnterSlide"
				@enter="enterSlide"
			>
				<div v-for="(image, index) in slide_images" :key="index" :data-index="index" v-show="counter === index" class="slide">
					<div class="slide_inner">

						<!-- left -->
						<div class="left flex-half flex-gutter-side" :class="'left' + index">
							
							<h3 class="slide_header" :class="'el_animate' + randNum + index">
								{{ $t('slider.' + langObject + '.slides[' + index + '].intro_header') }}
							</h3>
							
							<p class="slide_text" :class="'el_animate' + randNum + index">
								{{ $t('slider.' + langObject + '.slides[' + index + '].intro') }}
							</p>
							<h5 class="slide_text_two" :class="'el_animate' + randNum + index" v-if="multiSlideElements">
								{{ $t('slider.' + langObject + '.slides[' + index + '].intro_strong') }}
							</h5>
							<p class="slide_text_three" :class="'el_animate' + randNum + index" v-if="multiSlideElements">
								{{ $t('slider.' + langObject + '.slides[' + index + '].intro_last_text') }}
							</p>
							
							<!-- special link -->
							<span v-html="$t('slider.' + langObject + '.slides[' + index + '].intro_link_href')" 
							v-if="multiSlideElements" :class="'el_animate' + randNum + index" class="slide_link"></span> 
						</div>

						<!-- right -->
						<div class="right flex flex-half" :class="'right' + index">
							
							<div class="flex-half flex-gutter-side">
								<div class="slide_img_container">
									<img :src="require(`@/assets/img/${image}`)" class="slide_img" :class="'el_animate' + randNum + index">
								</div>
							</div>

							<div class="slide_story flex-half flex-gutter-side">
								<h3 class="slide_story_header" :class="'el_animate' + randNum + index">
									{{ $t('slider.' + langObject + '.slides[' + index + '].story_header') }}
								</h3>

								<div class="slide_story_truncate" :class="'el_animate' + randNum + index">
									<p>
										<!-- <img :src="require(`@/assets/img/${image}`)" class="slide_img_mobile" /> -->
										{{ $t('slider.' + langObject + '.slides[' + index + '].story_truncate') }}
									</p>
								</div>

								<div class="read_more"  :class="'el_animate' + randNum + index" @click="openLightbox(index)">
									{{ $t('read_more') }}
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="width: 1em; height: 1em;"><path id="Path_1454" data-name="Path 1454" d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z" transform="translate(-4 -4)" fill="#1a1a1a"></path></svg>
								</div>
							</div>
						</div>
					</div>
				</div> 
				<!-- end for -->
			</transition-group>
		</div>

		<!-- loop lightboxes -->
		<div class="lightbox_overlay" :class="'lightbox_overlay' + randNum" @click="closeLightbox"></div>
		
		<div class="lightbox_container" :class="'lightbox_container' + randNum">
			<div class="container">
				<div @click="closeLightbox" class="lightbox_close">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" style="width: 0.875em; height: 0.875em;"><path id="Path_1499" data-name="Path 1499" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)" fill="#fff"></path></svg>	
				</div>
				<div v-for="(image, index) in slide_images" :key="index" :data-index="index" class="lightbox"
					:ref="el => { if (el) lightboxes_refs[index] = el }"
				>	
				<p class="flex-gutter-side">{{ $t('slider.' + langObject + '.slides[' + index + '].lightbox_indicator') }}</p>
					<div class="lightbox_content">
						
						<!-- right text 75% -->
						<div class="lightbox_text_col">
							<div class="flex-header flex-gutter-side">
								<h3>
									{{ $t('slider.' + langObject + '.slides[' + index + '].lightbox_header') }}
								</h3>
							</div>
							<p class="ingress flex-gutter-side">{{ $t('slider.' + langObject + '.slides[' + index + '].lightbox_ingress') }}</p>
							
							<div class="two_col_50 flex">
								<div class="flex-half flex-gutter-side flex-gutter-right">{{ $t('slider.' + langObject + '.slides[' + index + '].lightbox_column1') }}</div>
								<div class="flex-half flex-gutter-side flex-gutter-right">{{ $t('slider.' + langObject + '.slides[' + index + '].lightbox_column2') }}</div>
							</div>

						</div>
						
						<!-- left image -->
						<div class="lightbox_img_col">
							<div class="lightbox_img_container">
								<img :src="require(`@/assets/img/${image}`)" class="lightbox_img">
							</div>
							<span class="lightbox_img_caption" v-if="lightboxImgCaption" v-html="$t('slider.' + langObject + '.slides[' + index + '].lightbox_img_caption')"></span>
						</div>
					</div>

				<div style="clear:both;"></div>
				</div>

			</div>
		</div>
	
	</section>

</template>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'

export default {
	name: 'Slider',
	props:{
		numSlides: String,
		slide_images: Array,
		bullitColor: Boolean,
		langObject: String,
		multiSlideElements: Boolean,
		lightboxImgCaption: Boolean
	},
	setup(props){
		const counter = ref(0)
		const langObject = props.langObject
		const multiSlideElements = props.multiSlideElements
		const lightboxImgCaption = props.lightboxImgCaption
		const numSlides = parseInt(props.numSlides)
		const slide_images = props.slide_images
		const lightboxes_refs = ref([])
		const randNum = '_' + Math.floor(Math.random() * 1000000) + '_'
		
		// COUNTER
		const next = () => {
			counter.value++
			if(counter.value >= numSlides){
				counter.value = 0
			}
		}

		const prev = () => {
			counter.value--
			if( counter.value < 0){
				counter.value = (numSlides - 1)
			}
		}

		const gotoSlide = (num) => {
			counter.value = num

		}

		// LIGHTBOX
		const openLightbox = (index) => {

			lightboxes_refs.value[index].style.opacity = "0";
			lightboxes_refs.value[index].style.display = "block";
			// lightboxes_refs.value[index].style.marginTop = "40 px";
			

			gsap.to(lightboxes_refs.value[index],{
				duration: 0.5,
				opacity:1
			})
			// gsap.set(['.lightbox_overlay', '.lightbox_container'], {opacity: 0, display: 'block'})
			gsap.set(['.lightbox_overlay' + randNum, '.lightbox_container' + randNum], {opacity: 0, display: 'block'})
			// gsap.set('.lightbox_container' + randNum, { top: '+=40'})
			gsap.set('.lightbox_container' + randNum, { top: '55%'})

			gsap.to('.lightbox_overlay' + randNum, { duration: 0.5, opacity:0.6 })
			// gsap.to('.lightbox_container' + randNum, { duration: 0.5, opacity: 1, top: '-=40' })
			gsap.to('.lightbox_container' + randNum, { duration: 0.5, opacity: 1, top: '50%' })
			gsap.to('.lightbox_container' + randNum, { duration: 0.5, opacity: 1 })

			console.log('open light', randNum);

		}
		const closeLightbox = () => {
			gsap.to(['.lightbox', '.lightbox_overlay', '.lightbox_container'],{
				duration: 0.5,
				top: '55%',
				opacity:0,
				onComplete: function(){
					gsap.set('.lightbox', {display: 'none'})
					gsap.set('.lightbox_overlay', {display: 'none'})
				}
			})

			console.log('close light', randNum);
		}

		// ANIMATIONS
		const leaveSlide = (el, done) => {
			const ar = document.querySelectorAll(".el_animate" + randNum + el.dataset.index);
			
			for(let i = 0; i < ar.length; i++){

				gsap.to(ar[i], {
				 	opacity: 0,
					duration:0.5,
					y: '-100px',
					ease: 'power2.in',
					delay: 0 + (i * 0.1)
				})

				gsap.to(el, {
				 	// duration:1.1,
				 	duration:1.45,
					onComplete: done,
				})
			}
		}

		const beforeEnterSlide = (el, done) => {
			const ar = document.querySelectorAll(".el_animate" + randNum + el.dataset.index);

			for(let i = 0; i < ar.length; i++){
				gsap.set(ar[i], {y: '100px', opacity:0 })
			}
		}

		const enterSlide = (el, done) => {
			const ar = document.querySelectorAll(".el_animate" + randNum + el.dataset.index);

			for(let i = 0; i < ar.length; i++){
				gsap.to(ar[i], {
					duration:1.5,
					y: '0px',
					opacity: 1,
					ease: 'power2.out',
					onComplete: done,
					delay: 1 + (i * 0.1)
				})
			}
		}
		// intercept 
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				// animate.value = entry.isIntersecting;
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{
				threshold: 0.6
			}
		);

		// INTERCEPT
		const inView = () => {

			// line 
			gsap.to('.timeline_bar' + randNum, {
				duration:1.2,
				delay: 0.1,
				scaleX: 1,
				ease: 'power1.out',
			})

			let bullits = []
			
			// loop
			for(let i = 1; i <= numSlides; i++){				
				// push bullits
				bullits.push('.slider_nav_item' + randNum + i)
				// gsap.set('.slider_nav_item' + randNum + i, {x: '-1000%' })

				// text
				gsap.to('.slider_nav_item_text' + randNum + i, {
					duration:1.2,
					delay: 2,
					y: '0px',
					opacity: 1,
					ease: 'power1.out',
					delay: 1.3 + (i * 0.2)
				})
			}

			// bullits
			bullits.reverse()

			for(let i = 0; i < bullits.length; i++){
				gsap.to(bullits[i], {
					duration:1.5,
					x: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: 0 + (i * 0.1)
				})
			}

			// array slide 0 elements
			const ar = document.querySelectorAll(".el_animate" + randNum + "0");
			
			// loop slide elements // gsap animate
			for(let i = 0; i < ar.length; i++){

				gsap.to(ar[i], {
					duration:1.5,
					y: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: 2.2 + (i * 0.1)
				})
			}

		}
		// END INTERCEPT

		onMounted(() => {

			// intercept 
			observer.observe(target.value);
			
			// init gsap
			gsap.set('.timeline_bar' + randNum, {scaleX: 0, transformOrigin:"0% 50%" })
			
			for(let i = 1; i <= numSlides; i++){				
				gsap.set('.slider_nav_item' + randNum + i, {x: '-1000%' })
				gsap.set('.slider_nav_item_text' + randNum + i, {y: '-40px', opacity:0})
			}

			// array slide 0 elements
			const ar = document.querySelectorAll(".el_animate" + randNum + "0");
			
			// loop slide elements // set gsap init
			for(let i = 0; i < ar.length; i++){
				gsap.set(ar[i], {y: '100px', opacity:0 })
			}

		})
		return{
			counter,
			numSlides,
			slide_images,
			randNum,
			langObject,
			multiSlideElements,
			lightboxImgCaption,

			next,
			prev,
			gotoSlide,

			openLightbox,
			closeLightbox,
			lightboxes_refs,

			leaveSlide,
			beforeEnterSlide,
			enterSlide,

			target
		}
	}
}

</script>