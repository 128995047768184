<template>
	<div class="accordion">
		<div class="closedAcc" v-html="textClosed"></div> 
		<div ref="openAcc" class="openAcc" :class="'openAcc' + randNum" v-html="textOpen" v-if="useAccordion"></div>
		<a aria-label="Expand text to read more" class="accordion_button" @click="changeAccordion" :data-accstatus="false" v-if="useAccordion">
			<span class="btn_open">{{ $t('btn_expander_open') }}</span>
			<span class="btn_close">{{ $t('btn_expander_close') }}</span>
			<span class="arr"><i></i></span>
		</a>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'
import { accordionParallax } from '@/composables/parallax';

export default {
	name: 'Accordion',
	props: {
		textClosed: Array,
		textOpen: Array,
		useAccordion: Boolean
	},
	setup(props){
		const textClosedArr = props.textClosed
		const textOpenArr = props.textOpen
		const useAccordion = props.useAccordion

		let textClosed = ''
		let textOpen = ''
		const randNum = '_' + Math.floor(Math.random() * 1000000)

		let switchBtnText = false;

		// compile html string
		for(let i = 0; i < textClosedArr.length; i++){
			textClosed += textClosedArr[i]
		}

		for(let i = 0; i < textOpenArr.length; i++){
			textOpen += textOpenArr[i]
		}

		// click accordion
		const changeAccordion = (event) => {
			if(event.target.dataset.accstatus == 'false'){
				event.target.dataset.accstatus = 'true';
				switchBtnText = !switchBtnText;				

				gsap.set('.openAcc' + randNum, {height:"auto"})
    			gsap.from('.openAcc' + randNum, {duration: 0.5, height:0,
					onComplete: function(){
						accordionParallax()
					}
				})

			}else{
				switchBtnText = !switchBtnText;
				event.target.dataset.accstatus = 'false';

				gsap.to('.openAcc' + randNum, {duration: 0.5, height:0,
					onComplete: function(){
						accordionParallax()
					}
				})

			}
		}

		onMounted(() => {
			// set acc body to 0
			if(useAccordion){
				gsap.set('.openAcc', {height:"0"})
			}
		})
		return{
			textClosed,
			textOpen,
			useAccordion,
			randNum,
			switchBtnText,
			changeAccordion
		}
	}
}
</script>

<style>

</style>
