<template>
	<section class="section_hcit">
		<div class="hcit" ref="target" :id="'hcit' + randNum" v-bind:class="{ whiteText: whiteText }">
			<div class="hcit_container"> 
				<div class="flex">
					<div class="hcit-image-col flex-half">
						<img :src="require(`@/assets/img/${image}`)" class="hcit_image" alt="" :id="'hcit_image' + randNum" :class="'hcit_image' + randNum">
					</div>

					<div class="hcit_content flex-half" :class="'hcit_content' + randNum">

						<h2 class="animated_header">
							<span v-bind:class="{ show_arrow: showArrow, hide_arrow: !showArrow }">&nbsp;
								<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 160.5 140.1" style="enable-background:new 0 0 160.5 140.1;" xml:space="preserve"><polygon fill="#ffffff" points="90.3,140.1 85,134.3 145.1,73.6 0,73.4 0,66.3 144.9,66.3 85,5.5 90.5,0 160.5,70.1"/></svg>
							</span>

							<span v-if="boldHeaderFirst">
								<span :class="'header_bold' + randNum" class="header_bold" v-html="hcit_headline_first[0]"></span>
								<span :class="'header_light' + randNum" class="header_light" v-html="hcit_headline_second"></span>
							</span>

							<span v-if="!boldHeaderFirst">
								<span :class="'header_bold' + randNum" class="header_light" v-html="hcit_headline_first[0]"></span>
								<span :class="'header_light' + randNum" class="header_bold" v-html="hcit_headline_second"></span>
							</span>

						</h2>

						
						<Accordion 
							:textClosed="textClosedArr" 
							:textOpen="textOpenArr"
							:useAccordion="useAccordion"
						/>
				
						<!-- extra -->
						<div v-if="extraContent.length > 0">
							<div class="extra_content">
								<div class="flex">
									<div class="flex-half" v-for="(content, index) in extraContent" :key="index">
										<div v-html="content"></div>
									</div>
								</div>
							</div>
						</div>

					</div>
				
				</div> <!-- flex -->
			</div> <!-- hcit container -->
		</div>
	</section>
</template>

<script>
// Half Col Image Text = hcit
import { computed, ref, onMounted, onUnmounted } from 'vue'
import Accordion from '@/components/Accordion.vue'
import gsap from 'gsap'
import {SplitText} from '@/assets/js/SplitText.min.js';

export default {
	name:'HalfColImageText',
	props: {
		textClosed: Array,
		textOpen: Array,
		hcit_headline_first: Array,
		hcit_headline_second: Array,
		showArrow: Boolean,
		whiteText: Boolean,
		boldHeaderFirst: Boolean,
		useAccordion: Boolean,
		extraContent: Array,
		image: String
	},
	components: {
		Accordion
	},
	setup(props){
		const textClosedArr = props.textClosed
		const textOpenArr = props.textOpen
		const randNum = '_' + Math.floor(Math.random() * 1000000)
		const whiteText = props.whiteText
		const showArrow = props.showArrow
		const boldHeaderFirst = props.boldHeaderFirst
		const useAccordion = props.useAccordion
		const hcit_headline_first = props.hcit_headline_first
		const hcit_headline_second = props.hcit_headline_second
		const extraContent = props.extraContent

		// collect accordion elements for animation
		let ar = []
		if(useAccordion == true){
			ar = ['.hcit_content' + randNum + ' .closedAcc .hcit_text1', '.hcit_content' + randNum + ' .accordion_button' ]
		}else{
			ar = ['.hcit_content' + randNum + ' .closedAcc .hcit_text1' ]
		}

		if(extraContent.length > 0){
			ar.push('.extra_content')
		}

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.7 }
		);

		// INTERCEPT ANIMATION
		const inView = () => {

			// SPLIT TEXT
			// type:"words, lines, chars"
			// postion: "relative" = default || postion: "absolute" || obs absolute pajjar text flow vid minskning i size
			
			gsap.set(['.header_bold' + randNum,'.header_light' + randNum], {opacity: 1})
			const header_bold = new SplitText('.header_bold' + randNum, {type:"words"})
			const header_light = new SplitText('.header_light' + randNum, {type:"words"})

			if(showArrow){
				gsap.set(header_bold.words, {opacity: 0, x: '-50', y: '0', scale: 1})
				gsap.set(header_light.words, {opacity: 0, x: '0', y: '30', scale: 1})
				gsap.set('.show_arrow', {opacity: 0, x: '-50', y: '0', scale: 1})

				gsap.to('.show_arrow', {
					duration: 0.7,
					opacity: 1,
					x: 0,
					y: 0,
					scale: 1,
					ease: 'back.out(5)',
					delay: 0.3
				})


				for(let i = 0; i < header_bold.words.length; i++){
					gsap.to(header_bold.words[i], {
						duration: 0.7,
						opacity: 1,
						x: '0',
						y: 0,
						scale: 1,
						ease: 'power2.out',
						delay: 0.8 + (i * 0.05	) // 0.3
					})
				}

				// header_light loop
				for(let i = 0; i < header_light.words.length; i++){
					gsap.to(header_light.words[i], {
						duration: 0.7,
						opacity: 1,
						x: '0',
						y: 0,
						scale: 1,
						ease: 'power2.out',
						delay: 1.4 + (i * 0.05	)
					})
				}

				// accodion elements
				for(let i = 0; i < ar.length; i++){
					gsap.to(ar[i], {
						duration: 1.5,
						y: '0px',
						opacity: 1,
						ease: 'power2.out',
						delay: 1.6 + (i * 0.1)
					})
				}
			// no arrow
			}else{
				gsap.set(header_bold.words, {opacity: 0, x: '0', y: '30', scale: 1})
				gsap.set(header_light.words, {opacity: 0, x: '-50', y: '0', scale: 1})

				for(let i = 0; i < header_bold.words.length; i++){
					gsap.to(header_bold.words[i], {
						duration: 0.7,
						opacity: 1,
						x: '0',
						y: 0,
						scale: 1,
						ease: 'power2.out',
						delay: 0.3 + (i * 0.05	)
					})
				}

				// header_light loop
				for(let i = 0; i < header_light.words.length; i++){
					gsap.to(header_light.words[i], {
						duration: 0.7,
						opacity: 1,
						x: '0',
						y: 0,
						scale: 1,
						ease: 'power2.out',
						delay: 1.0 + (i * 0.05	)
					})
				}

				// accodion elements
				for(let i = 0; i < ar.length; i++){
					gsap.to(ar[i], {
						duration: 1.2,
						y: '0px',
						opacity: 1,
						ease: 'power2.out',
						delay: 1.2 + (i * 0.1)
					})
				}				
			} // end no arrow

			// image person
			gsap.to('.hcit_image' + randNum, {
				duration: 1.0,
				y: 0,
				x: 0,
				opacity: 1,
				ease: 'power2.out',
				delay: 1.0
			})

		}
		// END INTERCEPT

		onMounted(()=>{
			

			// onMounted INTERCEPT
			observer.observe(target.value);
			
			// header
			if(showArrow){
				gsap.set(['.header_bold' + randNum,'.header_light' + randNum,'.show_arrow'], {opacity: 0})
			}else{
				gsap.set(['.header_bold' + randNum,'.header_light' + randNum], {opacity: 0})
			}

			// accodrion content
			gsap.set(ar, {y: '50px', opacity:0 })
			gsap.set('.hcit_image' + randNum, {x: '-50px', opacity: 0 })
			
		})

		return {
			textClosedArr,
			textOpenArr,
			whiteText,
			target,
			hcit_headline_first,
			hcit_headline_second,
			boldHeaderFirst,
			randNum,
			useAccordion,
			extraContent
		}

	}
}
</script>

<style scoped>

</style>