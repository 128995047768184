<template>
	<section class="section_tcib section_parallax">
		<div class="tcib" ref="target" :id="'tcib' + randNum" v-bind:class="{ whiteText: whiteText }">
			
			<picture class="parallax_container">
				<img :src="require(`@/assets/img/${background}`)" class="parallax_image animated_image" alt="" :id="'parallax_image' + randNum">
			</picture>
			
				<div class="tcib_content" :class="'tcib_content' + randNum">

					<h2 class="animated_header">
						<span v-bind:class="{ show_arrow: showArrow, hide_arrow: !showArrow }">&nbsp;
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 160.5 140.1" style="enable-background:new 0 0 160.5 140.1;" xml:space="preserve"><polygon fill="#ffffff" points="90.3,140.1 85,134.3 145.1,73.6 0,73.4 0,66.3 144.9,66.3 85,5.5 90.5,0 160.5,70.1"/></svg>
						</span>

						<span v-if="boldHeaderFirst">
							<span :class="'header_bold' + randNum" class="header_bold" v-html="tcib_headline_first[0]"></span>
							<span :class="'header_light' + randNum" class="header_light" v-html="tcib_headline_second"></span>
						</span>

						<span v-if="!boldHeaderFirst">
							<span :class="'header_bold' + randNum" class="header_light" v-html="tcib_headline_first[0]"></span>
							<span :class="'header_light' + randNum" class="header_bold" v-html="tcib_headline_second"></span>
						</span>

					</h2>

					
					<Accordion 
						:textClosed="textClosedArr" 
						:textOpen="textOpenArr"
						:useAccordion="useAccordion"
					/>
			
					<!-- extra -->
					<div v-if="extraContent.length > 0">
						<div class="extra_content">
							<div class="flex">
								<div class="flex-half" v-for="(content, index) in extraContent" :key="index">
									<div v-html="content"></div>
								</div>
							</div>
						</div>
					</div>

				</div>
		</div>
	</section>
</template>

<script>
// Text Center Image Bg = tcib
import { computed, ref, onMounted, onUnmounted } from 'vue'
import Accordion from '@/components/Accordion.vue'
import gsap from 'gsap'
import {SplitText} from '@/assets/js/SplitText.min.js';

export default {
	name:'TextCenterImageBg',
	props: {
		textClosed: Array,
		textOpen: Array,
		tcib_headline_first: Array,
		tcib_headline_second: Array,
		background: String,
		showArrow: Boolean,
		whiteText: Boolean,
		boldHeaderFirst: Boolean,
		useAccordion: Boolean,
		extraContent: Array
	},
	components: {
		Accordion
	},
	setup(props){
		const textClosedArr = props.textClosed
		const textOpenArr = props.textOpen
		const randNum = '_' + Math.floor(Math.random() * 1000000)
		const background = props.background
		const whiteText = props.whiteText
		const showArrow = props.showArrow
		const boldHeaderFirst = props.boldHeaderFirst
		const useAccordion = props.useAccordion
		const tcib_headline_first = props.tcib_headline_first
		const tcib_headline_second = props.tcib_headline_second
		const extraContent = props.extraContent

		// collect accordion elements for animation
		let ar = []
		if(useAccordion == true){
			ar = ['.tcib_content' + randNum + ' .closedAcc .tcib_text1', '.tcib_content' + randNum + ' .accordion_button' ]
		}else{
			ar = ['.tcib_content' + randNum + ' .closedAcc .tcib_text1' ]
		}

		if(extraContent.length > 0){
			ar.push('.extra_content')
		}

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.7 }
		);

		// INTERCEPT ANIMATION
		const inView = () => {

			// SPLIT TEXT
			// type:"words, lines, chars"
			// postion: "relative" = default || postion: "absolute" || obs absolute pajjar text flow vid minskning i size
			
			gsap.set(['.header_bold' + randNum,'.header_light' + randNum], {opacity: 1})
			const header_bold = new SplitText('.header_bold' + randNum, {type:"words"})
			const header_light = new SplitText('.header_light' + randNum, {type:"words"})

			if(showArrow){
				gsap.set(header_bold.words, {opacity: 0, x: '-50', y: '0', scale: 1})
				gsap.set(header_light.words, {opacity: 0, x: '0', y: '30', scale: 1})
				gsap.set('.show_arrow', {opacity: 0, x: '-50', y: '0', scale: 1})

				gsap.to('.show_arrow', {
					duration: 0.7,
					opacity: 1,
					x: 0,
					y: 0,
					scale: 1,
					ease: 'back.out(5)',
					delay: 0.3
				})


				for(let i = 0; i < header_bold.words.length; i++){
					gsap.to(header_bold.words[i], {
						duration: 0.7,
						opacity: 1,
						x: '0',
						y: 0,
						scale: 1,
						ease: 'power2.out',
						delay: 0.8 + (i * 0.05	) // 0.3
					})
				}

				// header_light loop
				for(let i = 0; i < header_light.words.length; i++){
					gsap.to(header_light.words[i], {
						duration: 0.7,
						opacity: 1,
						x: '0',
						y: 0,
						scale: 1,
						ease: 'power2.out',
						delay: 1.4 + (i * 0.05	)
					})
				}

				// accodion elements
				for(let i = 0; i < ar.length; i++){
					gsap.to(ar[i], {
						duration: 1.5,
						y: '0px',
						opacity: 1,
						ease: 'power2.out',
						delay: 1.6 + (i * 0.1)
					})
				}
			// no arrow
			}else{
				gsap.set(header_bold.words, {opacity: 0, x: '0', y: '30', scale: 1})
				gsap.set(header_light.words, {opacity: 0, x: '-50', y: '0', scale: 1})

				for(let i = 0; i < header_bold.words.length; i++){
					gsap.to(header_bold.words[i], {
						duration: 0.7,
						opacity: 1,
						x: '0',
						y: 0,
						scale: 1,
						ease: 'power2.out',
						delay: 0.3 + (i * 0.05	)
					})
				}

				// header_light loop
				for(let i = 0; i < header_light.words.length; i++){
					gsap.to(header_light.words[i], {
						duration: 0.7,
						opacity: 1,
						x: '0',
						y: 0,
						scale: 1,
						ease: 'power2.out',
						delay: 1.0 + (i * 0.05	)
					})
				}

				// accodion elements
				for(let i = 0; i < ar.length; i++){
					gsap.to(ar[i], {
						duration: 1.2,
						y: '0px',
						opacity: 1,
						ease: 'power2.out',
						delay: 1.2 + (i * 0.1)
					})
				}				
			} // end no arrow

		}
		// END INTERCEPT

		onMounted(()=>{
			const body = document.getElementsByTagName("BODY")[0];		
			const comp = document.getElementById('tcib' + randNum);
			const offset = comp.getBoundingClientRect();
			const top = offset.top;
			const height = comp.offsetHeight;

			// SCROLL PARALLAX
			const handleScroll = (evt) => {
				const window_height = window.innerHeight
				const image = document.getElementById('parallax_image' + randNum);
				const comp = document.getElementById('tcib' + randNum);
				const comp_box = comp.getBoundingClientRect();
				const comp_height = comp.offsetHeight // 812
				const comp_top = comp_box.top;

				if(comp_top <= window_height){
					const value = -1 * (((250 / (window_height + comp_height)) * comp_top) + 125)
					image.style.top = value + 'px'
				}
			}
			// END SCROLL PARALLAX

			// onMounted INTERCEPT
			observer.observe(target.value);
			document.addEventListener('scroll', handleScroll)
			handleScroll()
			
			// header
			if(showArrow){
				gsap.set(['.header_bold' + randNum,'.header_light' + randNum,'.show_arrow'], {opacity: 0})
			}else{
				gsap.set(['.header_bold' + randNum,'.header_light' + randNum], {opacity: 0})
			}

			// accodrion content
			gsap.set(ar, {y: '50px', opacity:0 })

			
		})

		return {
			textClosedArr,
			textOpenArr,
			whiteText,
			target,
			tcib_headline_first,
			tcib_headline_second,
			boldHeaderFirst,
			randNum,
			useAccordion,
			extraContent
		}

	}
}
</script>

<style scoped>
.parallax_image{
	opacity: 0.3;
}

</style>