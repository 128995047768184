<template>
	<section class="section_bubbles" ref="target">
		
		<div class="container">
		
		<div class="flex flex-third flex-gutter-side bubble_intro">
			<div class="flex-half">
				<h2>{{ $t('bubbles_header') }}</h2>
				<p class="bubbles_text">{{ $t('bubbles_text') }}</p>
			</div>
		</div>
		</div>
		<div class="container">
		
		<!-- pagination -->
			<ul class="bubble_nav">
				<li v-for="index in numBubbles" 
					:key="index"
					class="bubble_nav_item" 
					:class="'bubble_nav_item' + index"
				>
					<span class="text-regular bubble_nav_item_text" :class="'bubble_nav_item_text' + index">
						{{ $t('bubbles[' + (index - 1) + '].nav_text') }}
					</span>
				</li>
			</ul>
		</div>
		<div class="bubble_timeline_bar"></div>

		<!-- bubbles -->
		<div class="container">

			<!-- mobile bubbles -->
			<div class="container_mobile_bubbles">
				<div class="container_mobile_inner">

				<div
					v-for="index in numBubbles" 
					:key="index"
					class="mobile_bubbles" 
					:class="'mobile_bubble' + index"
				>
					<span class="mobile_bullit"></span>
					<h3 class="category">
						{{ $t('bubbles[' + (index - 1) + '].nav_text') }}
					</h3>
					<span class="mobile_description">
						{{ $t('bubbles[' + (index - 1) + '].description') }}
					</span>
					<span class="mobile_value">
						{{ $t('bubbles[' + (index - 1) + '].value') }}
					</span>
				</div> 
				</div> 
				<!-- for -->
			</div> 

			<!-- desktop bubbles -->
			<div class="bubbles flex">
				<div
					v-for="index in numBubbles" 
					:key="index"
					class="flex-sixth flex-gutter-side bubble" 
					:class="'bubble' + index"
				>
					<div class="bubble_line">
						<div class="animate_line"></div>

						<div class="splash bubble_splash">
								
							<div class="splash_bg_animated" :data-scale="$t('bubbles[' + (index - 1) + '].scale')"></div>
								
							<div class="splash_bg">
								<div class="splash_content">
									<div class="splash_text">
										<div class="splash_text_inner">
											{{ $t('bubbles[' + (index - 1) + '].value') }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<span class="description text-regular">{{ $t('bubbles[' + (index - 1) + '].description') }}</span>
				</div> 
				<!-- for -->
			
			</div>
		</div>
		
	
	</section>

</template>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'

export default {
	name: 'Slider',
	props:{
	},
	setup(props){
		
		const numBubbles = 6;
		const randNum = '_' + Math.floor(Math.random() * 1000000) + '_'

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.7 }
		);
		// INTERCEPT ANIMATION
		const inView = () => {
			
			// timebar
			gsap.to('.bubble_timeline_bar', {
				duration:1.2,
				delay: 0.1,
				scaleX: 1,
				ease: 'power1.out',
			})

			let bullits = []
			
			for(let i = 1; i <= numBubbles; i++){				
				bullits.push('.bubble_nav_item' + i)

				// text
				gsap.to('.bubble_nav_item_text' + i, {
					duration:1.2,
					delay: 1.7,
					y: '0px',
					opacity: 1,
					ease: 'power1.out',
					delay: 1.7 + (i * 0.2)
				})
			}

			// bullits
			bullits.reverse()

			for(let i = 0; i < bullits.length; i++){
				gsap.to(bullits[i], {
					duration:1.5,
					x: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: 0 + (i * 0.1)
				})
			}

			// bubbles
			const bubbles = document.querySelectorAll(".bubble_splash");
			const splashes = document.querySelectorAll(".splash_bg_animated");
			const lines = document.querySelectorAll(".animate_line");
			const descriptions = document.querySelectorAll(".description");
			
			for(let i = 0; i < (bubbles.length); i++){
				
				// lines height 100
				gsap.to(lines[i], {
					duration: 0.7,
					height: '100%',
					opacity: 1,
					ease: 'power2.out',
					delay: 2 + (i * 0.1),
					onComplete: function(){
						// hide lines
						gsap.set(lines[i], {backgroundColor: '#ffffff'});
					}
				})

				// bubble scale
				gsap.to(bubbles[i], {
					duration:1.5,
					scale: 1,
					opacity: 1,
					ease: 'back.out',
					delay: 2.5 + (i * 0.1)
				})

				// descriptions opacity
				gsap.to(descriptions[i], {
					duration:1.5,
					opacity: 1,
					ease: "none",
					delay: 2.5 + (i * 0.1)
				})
			}

		}
		// END INTERCEPT

		// INIT ANIMATION
		onMounted(() => {
			
			// onMounted INTERCEPT
			observer.observe(target.value);

			// init gsap
			gsap.set('.bubble_timeline_bar', {scaleX: 0, transformOrigin:"0% 50%" })
			
			for(let i = 1; i <= numBubbles; i++){				
				gsap.set('.bubble_nav_item' + i, {x: '-1000%' })
				gsap.set('.bubble_nav_item_text' + i, {y: '-40px', opacity:0})
			}

			// bubbles
			const bubbles = document.querySelectorAll(".bubble_splash");
			const splashes = document.querySelectorAll(".splash_bg_animated");
			const lines = document.querySelectorAll(".animate_line");
			const descriptions = document.querySelectorAll(".description");
			
			gsap.set(bubbles, {scale:0});
			gsap.set(descriptions, {opacity:0});

			for(let i = 0; i < (bubbles.length); i++){
				gsap.set(splashes[i], {scale: splashes[i].dataset.scale});
			}
		})
		return{
			numBubbles,
			target
		}
	}
}

</script>

<style>

</style>



