<template>
	<section class="section_page_title" ref="target">
		<div class="page_title_container">
			<div class="container">
				<div class="">
					<div class="">
						<h1 class="page_title">{{ $t('pageTitle') }}</h1>
					</div>
				</div>	
			</div>
		</div>
	</section>
</template>

<script>
import { ref, onMounted } from 'vue'
import gsap from 'gsap'
import {SplitText} from '@/assets/js/SplitText.min.js';

export default {
    name: "PageTitle",

    setup(){
		
		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.7 }
		);

		// INTERCEPT ANIMATION
		const inView = () => {
			// SPLIT TEXT
			// type:"words, lines, chars"
			// postion: "relative" = default || postion: "absolute" || obs absolute pajjar text flow vid minskning i size
			const title_words = new SplitText('.page_title', {type:"words"})
			gsap.set(title_words.words, {opacity: 0, x: '-80', y: '0', scale: 1})
			gsap.set(['.page_title'], {opacity: 1})
			
			// title_words loop
			for(let i = 0; i < title_words.words.length; i++){
				gsap.to(title_words.words[i], {
					duration: 1.5,
					opacity: 1,
					x: '0',
					y: 0,
					scale: 1,
					// ease: 'back.out(1.9)',
					ease: 'power2.out',
					delay: 0.0 + (i * 0.05	)
				})
			}
			
		}
		// END INTERCEPT

		// onMounted INTERCEPT
		onMounted(()=>{
			observer.observe(target.value);
			gsap.set(['.page_title'], {opacity: 0})
		})
        return{
target
        }
    }
}
</script>

<style>
	.page_title{
		padding:0 16px;
		width:100%;
		padding-top:5.9rem;
		text-align: center;
		margin-bottom: -3rem;
	}
</style>