<template>
	<section ref="target" class="section_case section_parallax" :class="'section_case' + randNum" :id="'section_case' + randNum">

		<div class="case_container">

			<div class="case_image_mobile_container">
				<picture class="">
					<img :src="require(`@/assets/img/${background}`)" class="case_image_mobile" alt="">
				</picture>
			</div>
			
			<div class="flex">

				<!-- text right -->
				<div class="flex-half case-col-image case-col-image-left" v-if="alignTextLeft">
					<div v-if="background === 'case_phenomenal.gif'" class="border"></div>
					<picture class="parallax_left">
						<img :src="require(`@/assets/img/${background}`)" class="case_image animated_image" alt="" :id="'parallax_image' + randNum">
					</picture>
				</div>

				<div class="flex-half case-col-content" v-if="alignTextLeft">
					<div class="content_right">
						<Accordion 
							:textClosed="textClosedArr" 
							:textOpen="textOpenArr" 
							:useAccordion="true"

						/>
					</div>
				</div>

				<!-- text left -->
				<div class="flex-half case-col-content" v-if="!alignTextLeft">
					<div class="content_left">
						<Accordion 
							:textClosed="textClosedArr" 
							:textOpen="textOpenArr" 
							:useAccordion="true"
						/>
					</div>
				</div>

				<div class="flex-half case-col-image case-col-image-right" v-if="!alignTextLeft">
					<div v-if="background === 'case_phenomenal.gif'" class="border"></div>
					<picture class="parallax_right">
						<img :src="require(`@/assets/img/${background}`)"  class="case_image animated_image" alt="" :id="'parallax_image' + randNum">
					</picture>
				</div>
			</div>
		</div>
	</section>
</template>
<script>

import { ref, onMounted } from 'vue'
import Accordion from '@/components/Accordion.vue'
import gsap from 'gsap'

export default {
	name: 'Case',
	props:{
		textClosed: Array,
		textOpen: Array,
		alignTextLeft: Boolean,
		background: String
	},
	components: {
		Accordion
	},
	setup(props){	

		const textClosedArr = props.textClosed
		const textOpenArr = props.textOpen
		const background = props.background
		const randNum = '_' + Math.floor(Math.random() * 1000000)

		const parentFunction = () => {
			console.log('Parent function körs')
		}

		// collect accordion elements for animation
		const ar = ['.section_case' + randNum + ' .closedAcc .case_indicator', '.section_case' + randNum + ' .closedAcc .case_header', '.section_case' + randNum + ' .closedAcc .case_text1', '.section_case' + randNum + ' .accordion_button' ]

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				// animate.value = entry.isIntersecting;
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{
				threshold: 0.6
			}
		);

		// INTERCEPT
		const inView = () => {

			// animate
			for(let i = 0; i < ar.length; i++){
				gsap.to(ar[i], {
					duration: 1.5,
					y: '0px',
					opacity: 1,
					ease: 'power2.out',
					delay: 0 + (i * 0.1)
				})
			}
		}
		// END INTERCEPT
		// MOUNTED
		onMounted(() => {
			
			// const body = document.getElementsByTagName("BODY")[0];		
			// const comp = document.getElementById('section_case' + randNum);
			// const offset = comp.getBoundingClientRect();
			// const top = offset.top;
			// const height = comp.offsetHeight;
			
			// const comp_box = comp.getBoundingClientRect();
			// const comp_height = comp.offsetHeight // 812
			

			// SCROLL PARALLAX
			const handleScroll = (evt) => {
				const window_height = window.innerHeight
				const image = document.getElementById('parallax_image' + randNum);
				const comp = document.getElementById('section_case' + randNum);
				const comp_box = comp.getBoundingClientRect();
				const comp_height = comp.offsetHeight // 812
				const comp_top = comp_box.top;

				if(comp_top <= window_height){
					const value = -1 * (((250 / (window_height + comp_height)) * comp_top) + 125)
					image.style.top = value + 'px'
				}
			}
			// END SCROLL PARALLAX
			// intercept 
			observer.observe(target.value);
			gsap.set(ar, {y: '100px', opacity:0 })

			document.addEventListener('scroll', handleScroll)
			handleScroll()

		})
		// END MOUNTED
		return{
			textClosedArr,
			textOpenArr,
			background,
			randNum,
			parentFunction,
			target
		}
	}
}
</script>

<style>

</style>