<template>



	<header>
		<PageTitle />

		<div v-if="isDesktop">
			<VideoHero />
		</div>
		<div v-else>
			<VideoHeroMobile />
		</div>
	</header>

	<main>
		<div class="scroll_rapid_change"></div>
		
		<TextCenterImageBg 
			:tcib_headline_first="[$t('tcib.empower_change.headline_first')]"
			:tcib_headline_second="[$t('tcib.empower_change.headline_second')]"

			:textClosed="[$t('tcib.empower_change.text1')]"
			:textOpen="[$t('tcib.empower_change.text2')]"
			
			background="tlib_bg_dark.jpg" 
			:showArrow="true"
			:whiteText="true"
			:boldHeaderFirst="false"
			:useAccordion="true"
			:extraContent="[]"
		/>

		<div class="scroll_numbers"></div>
		<!-- <CountNumbers /> -->
		<Numbers />

		<div class="scroll_strength"></div>
		<HalfColImageText 
			:hcit_headline_first="[$t('hcit.strength.headline_first')]"
			:hcit_headline_second="[$t('hcit.strength.headline_second')]"

			:textClosed="[$t('hcit.strength.text1')]"
			:textOpen="[$t('hcit.strength.text2')]"
			background="mj.jpg" 
			
			image="mj.jpg" 
			:showArrow="false"
			:whiteText="false"
			:boldHeaderFirst="true"
			:useAccordion="true"
			:extraContent="[]"
		/>

		<div class="scroll_case"></div>
		<Case
			:alignTextLeft="true"
			:textClosed="[$t('case.phenomenal.indicator'), $t('case.phenomenal.headline'), $t('case.phenomenal.text1')]" 
			:textOpen="[$t('case.phenomenal.text2')]"
			background="case_phenomenal.gif"
		/>

		<Case
			:alignTextLeft="false"
			:textClosed="[$t('case.futures.indicator'), $t('case.futures.headline'), $t('case.futures.text1')]" 
			:textOpen="[$t('case.futures.text2')]"
			background="case_futures.jpg"
		/>

		<Case
			:alignTextLeft="true"
			:textClosed="[$t('case.contactless.indicator'), $t('case.contactless.headline'), $t('case.contactless.text1')]" 
			:textOpen="[$t('case.contactless.text2')]"
			background="case_contact.jpg"
		/>

		<Svg
			:alignTextLeft="false"
			:textClosed="[$t('case.svg.indicator'), $t('case.svg.headline'), $t('case.svg.text1')]" 
			:textOpen="[$t('case.svg.text2')]"
			background="svg.svg"
		/>
		
		<div class="scroll_partner"></div>
		<TextCenterImageBg 
			:tcib_headline_first="[$t('tcib.reporter.headline_first')]"
			:tcib_headline_second="[$t('tcib.reporter.headline_second')]"

			:textClosed="[$t('tcib.reporter.text1')]"
			:textOpen="[$t('tcib.reporter.text1')]"
			
			background="miltton-house.jpg" 
			:showArrow="false"
			:whiteText="false"
			:boldHeaderFirst="false"
			:useAccordion="false"
			:extraContent="[$t('tcib.reporter.extra1'), $t('tcib.reporter.extra2')]"
		/>


		<Footer />

<!-- 


		<Video />
		<TextImageBg tlibHeader="tlib_ideas_header" tlibText="tlib_ideas_text" background="tlib_ideas.jpg" :showArrow="true" />
		<Slider
			:slide_images="['slider_value_01.jpg', 'slider_value_02.jpg', 'slider_value_03.jpg', 'slider_value_04.jpg']" 
			langObject="value"
			numSlides="4"
			:bullitColor="true"
			:multiSlideElements="true"
			:lightboxImgCaption="true"
		/>
		<BubbleValue />
		<TextImageBg tlibHeader="tlib_strategy_header" tlibText="tlib_strategy_text" background="tlib_strategy.jpg" :showArrow="true" />
		<Slider
			:slide_images="['slider_strategy_01.jpg', 'slider_strategy_02.jpg', 'slider_strategy_03.jpg']" 
			langObject="strategy"
			numSlides="3"
			:bullitColor="false" 
			:multiSlideElements="false"
			:lightboxImgCaption="false"
		/>
		<TextImageBg tlibHeader="tlib_sustain_header" tlibText="tlib_sustain_text" background="tlib_sustain.jpg" :showArrow="true" />
		<TextVideo /> -->
	</main>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'

import Video from '@/components/Video.vue'
import Hero from '@/components/Hero.vue'
import TextImageBg from '@/components/TextImageBg.vue'
import Slider from '@/components/Slider.vue'
import TextVideo from '@/components/TextVideo.vue'
import CountNumbers from '@/components/CountNumbers.vue'
import BubbleValue from '@/components/BubbleValue.vue'

import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import Footer from '@/components/Footer.vue'
import Case from '@/components/Case.vue'
import Svg from '@/components/Svg.vue'
import VideoHero from '@/components/VideoHero.vue'
import VideoHeroMobile from '@/components/VideoHeroMobile.vue'
import TextCenterImageBg from '@/components/TextCenterImageBg.vue'
import HalfColImageText from '@/components/HalfColImageText.vue'
import Numbers from '@/components/Numbers.vue'

export default {
	name: 'OnePage',
	components: {
		PageTitle,
		Hero,
		TextImageBg,
		Slider,
		TextVideo,
		BubbleValue,
		Video,

		LocaleSwitcher,
		CountNumbers,
		Footer,
		Case,
		Svg,
		VideoHero,
		VideoHeroMobile,
		TextCenterImageBg,
		HalfColImageText,
		Numbers
	},
	data(){
		// hide things on mobile
		const deviceType = () => {
			const ua = navigator.userAgent;
			if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
				return "tablet";
			}
			else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
				return "mobile";
			}
			return "desktop";
		};
		let isDesktop = true;
		// isDesktop = false;

		if( deviceType() == 'mobile'){
			isDesktop = false;
		}
		return{
			isDesktop
		}
	}
}
</script>