import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue'
import router from './router'
import i18n from './i18n'

import '@/assets/css/miltton.css'
import '@/assets/js/SplitText.min.js'

// const app = createApp(App).use(i18n).use(router).mount('#app')

const app = createApp(App)

const rootComponent = app.use(i18n)
.use(router)
.mount('#app')


    // app.config.globalProperties.$foo = 'bar';
    
    // console.log(app.config.globalProperties.$foo) 
    // console.log(app.config)