<template>

<nav>
	<div id="nav">
	<div class="nav_items">
	<div class="nav_inner">
		<ul class="menu menu_logo">
			<li class="nav_logo" @click="gotoTop">
				<div>
					<!-- logo Dark -->
					<!-- <svg version="1.1" id="logo_top_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 260.2 59.1" style="enable-background:new 0 0 260.2 59.1;" xml:space="preserve"><g><g><path class="st0" style="fill:#404257;" d="M63.2,29.4v28.4h-11V35.3c0-7.2-1.7-11.2-7.4-11.2c-5.1,0-7.7,4.2-7.7,12.9v20.7H26.2V35.3c0-5.8-1.1-11.2-7.3-11.2c-6.9,0-7.8,7.6-7.8,12.8v20.8H0V16.3h11.1v8.4h0.1c1.5-6.8,6-9.8,12.4-9.8c5.4,0,10.9,3.2,12.3,8.9c1.2-5.3,6.2-8.9,12.1-8.9C56.6,14.9,63.2,18.8,63.2,29.4z"/><path class="st0" style="fill:#404257;" d="M72.5,16.3h11.3v41.5H72.5V16.3z"/><path class="st0" style="fill:#404257;" d="M104.5,0.4v57.3H93.2V0.4H104.5z"/><path class="st0" style="fill:#404257;" d="M129.2,24.8v16.1c0,5.1,1,8.4,5.1,8.4h2.5v8.5h-5.5c-10.8,0-13.3-6.3-13.3-15.2V24.8h-6.7v-8.5h6.7V7.8l11-5.8h0.3v14.2h8.3v8.5H129.2z"/><path class="st0" style="fill:#404257;" d="M159.4,24.8v16.1c0,5.1,1,8.4,5.1,8.4h2.5v8.5h-5.5c-10.8,0-13.3-6.3-13.3-15.2V24.8h-6.7v-8.5h6.7V7.8l11-5.8h0.3v14.2h8.3v8.5H159.4z"/><path class="st0" style="fill:#404257;" d="M171.9,37c0-13.7,10-21.9,21.9-21.9s21.9,8.2,21.9,21.9c0,13.8-10,22.1-21.9,22.1C181.8,59.1,171.9,50.8,171.9,37z M204.2,37c0-8.3-4.8-12.8-10.4-12.8c-5.5,0-10.4,4.6-10.4,12.8s4.7,13,10.4,13C199.6,50,204.2,45.3,204.2,37z"/><path class="st0" style="fill:#404257;" d="M260.2,32.9v24.9h-11.3V35.9c0-6.2-1.1-11.8-7.2-11.8c-5.2,0-8.5,3.9-8.5,13.5v20.2h-11.3V16.3H233v7.2c1.8-4.9,5.6-8.6,12.2-8.6C256,14.9,260.2,22.1,260.2,32.9z"/><circle class="st0" style="fill:#404257;" cx="78.2" cy="6.1" r="6.1"/></g></g></svg> -->

					<!-- logo Black -->
					<svg version="1.1" id="logo_top_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 260.2 59.1" style="enable-background:new 0 0 260.2 59.1;" xml:space="preserve"><g><g><path class="st0" style="fill:#000000;" d="M63.2,29.4v28.4h-11V35.3c0-7.2-1.7-11.2-7.4-11.2c-5.1,0-7.7,4.2-7.7,12.9v20.7H26.2V35.3c0-5.8-1.1-11.2-7.3-11.2c-6.9,0-7.8,7.6-7.8,12.8v20.8H0V16.3h11.1v8.4h0.1c1.5-6.8,6-9.8,12.4-9.8c5.4,0,10.9,3.2,12.3,8.9c1.2-5.3,6.2-8.9,12.1-8.9C56.6,14.9,63.2,18.8,63.2,29.4z"/><path class="st0" style="fill:#000000;" d="M72.5,16.3h11.3v41.5H72.5V16.3z"/><path class="st0" style="fill:#000000;" d="M104.5,0.4v57.3H93.2V0.4H104.5z"/><path class="st0" style="fill:#000000;" d="M129.2,24.8v16.1c0,5.1,1,8.4,5.1,8.4h2.5v8.5h-5.5c-10.8,0-13.3-6.3-13.3-15.2V24.8h-6.7v-8.5h6.7V7.8l11-5.8h0.3v14.2h8.3v8.5H129.2z"/><path class="st0" style="fill:#000000;" d="M159.4,24.8v16.1c0,5.1,1,8.4,5.1,8.4h2.5v8.5h-5.5c-10.8,0-13.3-6.3-13.3-15.2V24.8h-6.7v-8.5h6.7V7.8l11-5.8h0.3v14.2h8.3v8.5H159.4z"/><path class="st0" style="fill:#000000;" d="M171.9,37c0-13.7,10-21.9,21.9-21.9s21.9,8.2,21.9,21.9c0,13.8-10,22.1-21.9,22.1C181.8,59.1,171.9,50.8,171.9,37z M204.2,37c0-8.3-4.8-12.8-10.4-12.8c-5.5,0-10.4,4.6-10.4,12.8s4.7,13,10.4,13C199.6,50,204.2,45.3,204.2,37z"/><path class="st0" style="fill:#000000;" d="M260.2,32.9v24.9h-11.3V35.9c0-6.2-1.1-11.8-7.2-11.8c-5.2,0-8.5,3.9-8.5,13.5v20.2h-11.3V16.3H233v7.2c1.8-4.9,5.6-8.6,12.2-8.6C256,14.9,260.2,22.1,260.2,32.9z"/><circle class="st0" style="fill:#000000;" cx="78.2" cy="6.1" r="6.1"/></g></g></svg>



				</div>
			</li>
		</ul>

		<ul class="menu menu_desktop menu_left">
			<li>
				<a @click="scrollTo('.section_video_hero', 0)">
					{{ $t('menu[0]') }}
				</a>
			</li>
			<li>
				<a @click="scrollTo('.scroll_rapid_change', 0)">
					{{ $t('menu[1]') }}
				</a>
			</li>
			<li>
				<a @click="scrollTo('.scroll_numbers', 0)">
					{{ $t('menu[2]') }}
				</a>
			</li>
			<li>
				<a @click="scrollTo('.scroll_strength', 0)">
					{{ $t('menu[3]') }}
				</a>
			</li>
			<li>
				<a @click="scrollTo('.scroll_case', 0)">
					{{ $t('menu[4]') }}
				</a>
			</li>
			<li>
				<a @click="scrollTo('.scroll_partner', 0)">
					{{ $t('menu[5]') }}
				</a>
			</li>
		</ul>

		<!-- <ul class="menu menu_desktop menu_right">
			<li>
				<router-link :class="{ activeMenuLangItem: activeMenuSE }" @click="switchLocale('se')" :to="{ name: 'Svenska', params: { locale: 'se' }}">Svenska</router-link> 
			</li>
			<li><span>|</span></li>
			<li>
				<router-link :class="{ activeMenuLangItem: activeMenuEN }" @click="switchLocale('en')" :to="{ name: 'English', params: { locale: 'en' }}">English</router-link> 
			</li>
			
		</ul> -->

		<!-- hamburger -->
		<div id="hamburger" @click="clickMenu">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>

	</div>
	</div>
	</div>
</nav>

<!-- mobile menu  -->
<nav>
	<div class="mobileMenu" id="mobileMenu" style="top:-110%;">

		<ul class="menu">
			<li>
				<a @click="scrollTo('.section_video_hero', 0)">
					{{ $t('menu[0]') }}
				</a>
			</li>
			<li>
				<a @click="scrollTo('.scroll_rapid_change', 0)">
					{{ $t('menu[1]') }}
				</a>
			</li>
			<li>
				<a @click="scrollTo('.scroll_numbers', 0)">
					{{ $t('menu[2]') }}
				</a>
			</li>
			<li>
				<a @click="scrollTo('.scroll_strength', 0)">
					{{ $t('menu[3]') }}
				</a>
			</li>
			<li>
				<a @click="scrollTo('.scroll_case', 0)">
					{{ $t('menu[4]') }}
				</a>
			</li>
			<li>
				<a @click="scrollTo('.scroll_partner', 0)">
					{{ $t('menu[5]') }}
				</a>
			</li>
		</ul>

		<!-- <ul class="menu menu_lang">
			<li>
				<router-link :class="{ activeMenuLangItem: activeMenuSE }" @click="switchLocale('se')" :to="{ name: 'Svenska', params: { locale: 'se' }}">Svenska</router-link> 
			</li>
			<li><span>|</span></li>
			<li>
				<router-link :class="{ activeMenuLangItem: activeMenuEN }" @click="switchLocale('en')" :to="{ name: 'English', params: { locale: 'en' }}">English</router-link> 
			</li>
		</ul> -->

	</div> 
	<!-- mobileMenu -->
</nav>

</template>


<script>
import { mounted, ref } from 'vue';

import gsap from 'gsap'
import {ScrollToPlugin} from '@/assets/gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'MainMenu',
  methods: {
	  
	switchLocale(locale){
	  if( this.$i18n.locale !== locale){
		this.$i18n.locale = locale
		this.activeMenuSE = false
		this.activeMenuEN = false

		// close mobile menu
		document.getElementById('mobileMenu').style.top = '-110%'
		document.getElementById('hamburger').className = ""
	  }
	},

	// scroll to module when menu click
	scrollTo(el, num){
		
		// close mobile menu
		document.getElementById('mobileMenu').style.top = '-110%'
		document.getElementById('hamburger').className = ""

		const sect = document.querySelectorAll(el);
		const sect_box = sect[num].getBoundingClientRect();
		const top = sect_box.top + window.scrollY;
		
		gsap.to(window, { duration: 1, scrollTo: top });

		document.getElementById('nav').style.top='-64px';
	},

	gotoTop(){
		gsap.to(window, { duration: 1, scrollTo: 0 });

		// close mobile menu
		document.getElementById('mobileMenu').style.top = '-110%'
		document.getElementById('hamburger').className = ""
	},
	
	// hamburger
	clickMenu: function() {
		document.getElementById('hamburger').classList.toggle('open')

		if(document.getElementById('mobileMenu').style.top == '0px'){
			document.getElementById('mobileMenu').style.top = '-110%'
		}else{
			document.getElementById('mobileMenu').style.top = '0px'
		}		
	}
  },

  mounted(){
	// Page scroll show/hide menu
	let lastScrollTop = 0;

	window.addEventListener("scroll", function(){  
		let st = window.pageYOffset || document.documentElement.scrollTop;  
		if (st > lastScrollTop){
			document.getElementById('nav').style.top = '-64px'
		} else {
			document.getElementById('nav').style.top = '0px'
		}
		lastScrollTop = st;
	}, false);

	// set language from url string /se /en
	const url = window.location.href;
	if(url.indexOf('/se') !== -1){
	  this.$i18n.locale = 'se'
	  this.activeMenuSE = true

	}else{
	  this.activeMenuEN = true
	}
  },
  data(){
	return {
		activeMenuSE: false,
		activeMenuEN: false,
		locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
	}
  }
}

</script>

<style scoped>

	.menu_left div, .menu_left a{
		cursor: pointer;
	}
	


  /* a{
	margin:0 10px;
  }
  .activeMenuLangItem{
	color: #42b983 !important;
  } */

</style>