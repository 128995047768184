{
	"menu":[
		"Vår film om 2020",
		"2020 i siffror",
		"Värdeskapande",
		"Strategisk utveckling",
		"Hållbarhet"
	],
	"read_more": "Läs hela caset",
	"tlib_ideas_header": "Vi möjliggör för människor och företag att förverkliga sina idéer",
	"tlib_ideas_text": "Vi står vid våra kunders sida i både goda och dåliga tider. Det har vi alltid gjort. Genom att möjliggöra för människor och företag att förverkliga sina idéer, drömmar och ambitioner utvecklar vi också samhällena där vi verkar och bidrar till en mer hållbar värld. Det är så vi skapar långsiktigt värde för våra kunder, medarbetare, aktieägare och samhället i stort.",
	"tlib_strategy_header": "Navigera i en föränderlig värld",
	"tlib_strategy_text": "För att fortsätta vara relevanta för våra kunder och andra intressenter behöver vi kontinuerligt anpassa, förnya och utmana vår verksamhet. Genom vår strategi och affärsplan ska vi påskynda transformationen, stärka Företaget:s långsiktiga lönsamhet och skapa långsiktigt värde för våra kunder, aktieägare och medarbetare.",
	"tlib_sustain_header": "Vi bidrar till en hållbar framtid",
	"tlib_sustain_text": "Klimatförändringarna är vår tids största utmaning och Företaget är, och kommer att vara, en del av omställningen. Som bank har vi ett stort ansvar för hur vi bedriver vår verksamhet, vad vi finansierar och vad vi investerar i. Vi samarbetar med och stödjer våra kunder i övergången till ett koldioxidsnålt samhälle.",
	"slider": {
		"strategy": {
			"header": "Strategisk utveckling under 2021",
			"text": "I vår affärsplan för 2019-2021 har vi identifierat tre fokusområden: vi ska stärka vår rådgivning, påskynda effektivitetsförbättringar i verksamheten och utöka vårt erbjudande digitalt.",
			"slides": [
				{
					"timeline_indicator": "Ledande inom<br/>rådfrågning",
					"intro_header": "Sammanfattning av aktiviteter 2020",
					"intro": "Under året har vi sett hur kundernas beteenden har fortsatt att förändras. Effekterna av covid‐19‐pandemin har ytterligare påskyndat behovet av kvalificerad rådgivning, digitala lösningar och stöd i omställningen till ett koldioxidsnålt samhälle. Tack vare vår finansiella styrka hade vi möjlighet att snabbt anpassa våra finansierings- och rådgivningstjänster för att motverka pandemins effekter för våra kunder. Vi har också fortsatt att utveckla vårt hållbarhetserbjudande för att möta våra kunders behov.",
					"intro_strong": "1.8 million",
					"intro_last_text": "corporate and private home bank customers.",
					"intro_link_text": "Do you want to know more about our services?",
					"intro_link_href": "https://google.com/",
					"story_header": "Rådgivning om gröna transaktioner",
					"story_truncate": "Marknaden för hållbar finansiering har tagit fart på alvar och Företaget har fungerat som rådgivare vid utgivningen av ett stort antal gröna och hållbarhetslänkade obligationer och lån.",
					"lightbox_indicator": "Hållbarhet",
					"lightbox_header": "Rådgivning om gröna transaktioner",
					"lightbox_ingress": "Marknaden för hållbar finansiering har tagit fart på allvar och Företaget har fungerat som rådgivare vid utgivningen av ett stort antal gröna och hållbarhetslänkade obligationer och lån.",
					"lightbox_column1": "Det enskilt största exemplet är utgivningen av svenska statens första gröna obligation som genomfördes under året. Obligationen på 20 miljarder kronor är den hittills största gröna obligation som har getts ut i Norden och i nordisk valuta. De pengar som lånas upp kommer att kopplas till utgifter i statsbudgeten som bidrar till att uppfylla Sveriges miljö- och klimatmål.",
					"lightbox_column2": "Företaget var Riksgäldens särskilda rådgivare vid framtagningen av ramverket, ett uppdrag som banken hösten 2019 vann i konkurrens med ett 15-tal banker. Företaget var också med och placerade obligationen på marknaden. Under året som gått har Företaget också varit ensam strukturell rådgivare till den globala fordonstillverkaren Daimler samt till Volvokoncernen."
				},
				{
					"timeline_indicator": "Operationell<br/>effektivitet",
					"intro_header": "Sammanfattning av aktiviteter 2021",
					"intro": "Våra kunder använder allt mer de digitala kanalerna, en utveckling som påskyndats under pandemin. Vi arbetar målmedvetet för att förstärka den digitala kundupplevelsen och vi har under året fortsatt att digitalisera och automatisera produkter och processer, samt att lägga till ny funktionalitet i våra digitala kanaler. Vår digitala transformationsagenda fortsätter, med nya sätt att arbeta, och med fokus på uppkoppling, automatisering och analysverktyg.",
					"intro_strong": "",
					"intro_last_text": "",
					"intro_link_text": "",
					"intro_link_href": "",
					"story_header": "Bekämpa finansiell brottslighet",
					"story_truncate": "”Vi utvecklar ständigt våra övervakningsprocesser och system i takt med att regelverk, teknik, kunskap och kriminella beteenden förändras”, säger Petra Ålund, chef för Group Technology.",
					"lightbox_indicator": "Teknisk utveckling",
					"lightbox_header": "Effektiv kamp mot finansiell brottslighet",
					"lightbox_ingress": "Banker spelar en viktig roll för att förebygga, upptäcka och rapportera misstänkt aktivitet kopplat till penningtvätt och annan finansiell brottslighet.",
					"lightbox_column1": "Företaget har under året samlat alla resurser som arbetar med att bekämpa finansiell brottslighet i en gemensam, virtuell organisation. Syftet är att kunna arbeta ännu mer effektivt mot bedrägerier, penningtvätt och andra typer av finansiell brottslighet som drabbar kunder, banken och samhället. Här samlas affärs- och tech-specialister som arbetar med transaktions- och marknadsövervakning.",
					"lightbox_column2": "”Vi utvecklar ständigt våra övervakningsprocesser och system i takt med att regelverk, teknik, kunskap och kriminella beteenden förändras”, säger Petra Ålund, chef för Group Technology. Stort fokus ligger på att öka takten i den tekniska utvecklingen vad gäller användningen av artificiell intelligens och visualiseringstekniker för att upptäcka misstänkta avvikelser."
				},
				{
					"timeline_indicator": "Utökad<br/>närvaro",
					"intro_header": "Sammanfattning av aktiviteter 2020",
					"intro": "Genom att ingå nya partnerskap med ledande leverantörer av ny teknik och nya finansiella tjänster förbättrade vi vårt kunderbjudande under året. Att utveckla vår verksamhet i samarbete med externa parter gör att vi kan möta våra kunder även på digitala plattformar utanför banken. Inom området Open Banking har ett antal nya API:er utvecklats för att stödja omställningen mot en modern teknikarkitektur och snabba upp utvecklingen och hur banken levererar kundvärde.",
					"intro_strong": "",
					"intro_last_text": "",
					"intro_link_text": "",
					"intro_link_href": "",
					"story_header": "Utökat kundsamarbete",
					"story_truncate": "Genom partnerskap med fintechbolag fortsatte vi vårt arbete med att erbjuda våra små och medelstora företagskunder digitala ecosystem som kopplar samman Företaget med deras egna affärssystem.",
					"lightbox_indicator": "Partnerskap",
					"lightbox_header": "Utökat kundsamarbete",
					"lightbox_ingress": "Våra partnerskap med fintech-bolag ökar i antal, med målet att ytterligare förbättra vårt erbjudande till våra små och medelstora företagskunder.",
					"lightbox_column1": "Företaget etablerade under året ett strategiskt partnerskap med fintechbolaget Oxceed som har utvecklat ett molnbaserat verktyg som ger små och medelstora företag bättre översikt och kontroll över sin ekonomi inom ett digitalt ekosystem. Genom att integrera Oxceed med sitt affärssystem kan ett företag visualisera och analysera aktuella bokföringsdata i realtid och därmed fatta mer välunderbyggda beslut.",
					"lightbox_column2": "Bland andra exempel finns PE Accounting, genom vilka företag får möjlighet att koppla samman sina affärssystem med Företaget och därmed uppnå en sömlös integration mellan bokföring och banktjänster. Och genom vårt partnerskap med Capcito kan våra kunder få en automatisk beräkning av sitt låneutrymme och slipper därmed mängder med pappersarbete i samband med en kreditansökan."
				}
			]
		},
		"value": {
			"header": "Så skapar vi långsiktigt värde",
			"text": "",
			"slides": [
				{
					"timeline_indicator": "Kunder",
					"intro_header": "För våra kunder",
					"intro": "Genom att tillhandahålla proaktiv rådgivning och ett brett utbud av digitala och personliga tjänster, stödjer vi våra kunders långsiktiga ambitioner och tillför värde i livets alla faser för privatpersoner och i alla utvecklingsstadier för företag och institutioner.",
					"intro_strong": "1,8 miljoner",
					"intro_last_text": "företags- och privathelkunder",
					"intro_link_text": "Vill du veta mer om våra tjänster?",
					"intro_link_href": "<a target='_blank' href='https://Företaget.se/'>Vill du veta mer om våra tjänster? <span class='link_arrow'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13' style='width: 0.75em; height: 0.75em;'><path id='Path_1458' data-name='Path 1458' d='M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z' transform='translate(-3 -3)' fill='currentColor'></path></svg></span></a>",
					"story_header": "Medtech-bolaget Getinge",
					"story_truncate": "“Företaget var proaktiva, hörde av sig och klargjorde att banken fanns där om det skulle behövas”, säger Lars Sandström, finanschef.",
					"lightbox_indicator": "Getinge",
					"lightbox_header": "“Det fungerar väldigt bra med Företaget”",
					"lightbox_ingress": "Getinge är ett globalt medicintekniskt företag som tillhandahåller lösningar för operationssalar, intensivvårdsavdelningar, sterilcentraler, forskningsinstitut och läkemedelsföretag.",
					"lightbox_column1": "Covid-19-pandemin har bland annat lett till kraftigt ökad efterfrågan på avancerade intensivvårdsrespiratorer. Som tillverkare av utrustning och system inom hälsovård och bioteknik, inklusive produkter till vaccintillverkare, har Getinge haft ett intensivt år.",
					"lightbox_column2": "“Det fungerar väldigt bra med Företaget, till exempel i början av pandemin när det fanns en osäkerhet om hur situationen skulle utvecklas. Företaget var proaktiv, hörde av sig och klargjorde att banken fanns där om det skulle behövas”, säger Lars Sandström, finanschef på Getinge. ”Trots att certifikatmarknaden var stendöd, lyckades vi med hjälp av Företaget låna upp en miljard kronor för att skala upp produktionen”.",
					"lightbox_img_caption": "Lars Sandström, <br/>finanschef, Getinge"
				},
				{
					"timeline_indicator": "Medarbetare",
					"intro_header": "För våra medarbetare",
					"intro": "Våra medarbetare värdesätter möjligheterna till kontinuerligt lärande inom banken. Medarbetarna deltar också i Företaget:s många partnerskap för att hjälpa samhällen att utvecklas och blomstra.",
					"intro_strong": "15 500",
					"intro_last_text": "medarbetare runt om i världen.",
					"intro_link_text": "Vill du jobba hos oss?",
					"intro_link_href": "<a target='_blank' href='https://google.com'>Vill du jobba hos oss? <span class='link_arrow'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13' style='width: 0.75em; height: 0.75em;'><path id='Path_1458' data-name='Path 1458' d='M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z' transform='translate(-3 -3)' fill='currentColor'></path></svg></span></a>",
					"story_header": "Zainab Imam Din",
					"story_truncate": "“Jag känner att Företaget värdesätter min kunskap och att min röst får höras”, säger Zainab Imam Din, fullstack-utvecklare och scrum master på Företaget.",
					"lightbox_indicator": "Zainab Imam Din",
					"lightbox_header": "“Företaget värdesätter min kunskap”",
					"lightbox_ingress": "Zainab Imam Din har arbetat som fullstack-utvecklare på Företaget i snart två år. Det hon uppskattar mest med sitt jobb är att hon lär sig något nytt varje dag.",
					"lightbox_column1": "Zainab har nyligen fått ett utökat ansvar som scrum master, en ledarroll inom agil utveckling. På dagarna skriver och implementerar hon kod – hela tiden i ett nära samarbete med andra utvecklare samt UX-designers (experter på gränssnitt) och affärsanalytiker.",
					"lightbox_column2": "Zainab berättar att det hon uppskattar mest med sitt jobb är att hon lär sig något nytt varje dag. Där bidrar kurserna som erbjuds i Företaget Campus till att hon kan hålla sig uppdaterad. ”Jag känner att Företaget värdesätter min kunskap och att min röst får höras”, säger hon. “Jag kan vara transparent och öppen mot mina chefer och vice versa. De ser min potential och hjälper mig att uppnå mina mål”.",
					"lightbox_img_caption": "Zainab Imam Din, <br/>full stack software developer and scrum master."

				},
				{
					"timeline_indicator": "Aktieägare",
					"intro_header": "För våra aktieägare",
					"intro": "Utdelning och marknadsvärdesökning över tid bidrar till aktieägarnas ekonomiska trygghet och möjliggör nya investeringar. Genom att integrera miljö-, sociala och styrningsrelaterade aspekter i verksamheten stärker vi vår konkurrenskraft och minskar långsiktiga risker.",
					"intro_strong": "268 000",
					"intro_last_text": "aktieägare.",
					"intro_link_text": "Intresserad av att investera i Företaget?",
					"intro_link_href": "<a target='_blank' href='https://google.com'>Intresserad av att investera i Företaget? <span class='link_arrow'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13' style='width: 0.75em; height: 0.75em;'><path id='Path_1458' data-name='Path 1458' d='M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z' transform='translate(-3 -3)' fill='currentColor'></path></svg></span></a>",
					"story_header": "Finansierar svenska pensionärer",
					"story_truncate": "Bland vår engagerade och långsiktigt fokuserade aktieägarbas finns några av Sveriges största pensionsfonder, vilka förser pensionärer med delar av vår utdelning.",
					"lightbox_indicator": "Aktieägarvärde",
					"lightbox_header": "Pensionärer, småsparare och forskning",
					"lightbox_ingress": "En stor del av den utdelning vi betalar går till några av Sveriges största pensionsfonder och till småsparare – samt till forskning och utbildning genom Wallenbergstiftelserna.",
					"lightbox_column1": "Ungefär 20 procent av aktierna i Företaget ägs av pensionsfonder som Alecta, AMF och de statliga AP-fonderna. Bland våra aktieägare finns också fler än 200 000 småsparare samt det industriella holdingbolaget Investor AB, som är vår enskilt största ägare med mer än 20 procent av aktiekapitalet. Delar av den utdelning som Investor får från Företaget finansierar forskning och utbildning, genom Wallenbergstiftelserna.",
					"lightbox_column2": "Under 2020 utbetalades ingen utdelning för 2019, i linje med kommunikation från Finansinspektionen. Vid årsstämman 2020 röstade Företaget:s aktieägare för att hela det disponibla beloppet balanseras i ny räkning. Bankens finansiella position är stark, och styrelsen föreslår en utdelning på 4:10 kronor per aktie för 2020, i linje med myndigheternas riktlinjer.",
					"lightbox_img_caption": "Företaget har engagerade aktieägare med ett långsiktigt perspektiv."
				},
				{
					"timeline_indicator": "Samhället",
					"intro_header": "För samhället",
					"intro": "Företaget förmedlar finansiella lösningar, tillhandahåller betaltjänster och hanterar risker, vilket sammantaget främjar ekonomisk tillväxt och välstånd. Vi betalar skatter och avgifter enligt lokala regler, vi tar ansvar i rollen som finansiär och kapitalförvaltare och vi arbetar proaktivt med miljö-, sociala och styrningsrelaterade frågor.",
					"intro_strong": "",
					"intro_last_text": "",
					"intro_link_text": "",
					"intro_link_href": "<a target='_blank' href='https://www.google.com'>Vill du veta mer om oss? <span class='link_arrow'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13' style='width: 0.75em; height: 0.75em;'><path id='Path_1458' data-name='Path 1458' d='M14.556,14.556H4.444V4.444H9.5V3H4.444A1.444,1.444,0,0,0,3,4.444V14.556A1.444,1.444,0,0,0,4.444,16H14.556A1.449,1.449,0,0,0,16,14.556V9.5H14.556ZM10.944,3V4.444h2.593l-7.1,7.1,1.018,1.018,7.1-7.1V8.056H16V3Z' transform='translate(-3 -3)' fill='currentColor'></path></svg></span></a>",
					"story_header": "Siktar på ekonomisk jämställdhet",
					"story_truncate": "Under 2020 belyste vi behovet av ökad jämställdhet mellan kvinnor och män vad gäller sparande och ägande, genom vår kampanj Financial Equality.",
					"lightbox_indicator": "Möjliggör framsteg",
					"lightbox_header": "Siktar på ökad ekonomisk jämställdhet",
					"lightbox_ingress": "Kvinnors ägande svarar endast för 15 procent av privat markegendom, 25 procent av fastighetsvärdet och 33 procent av privatägda aktier",
					"lightbox_column1": "Under 2020 startade Företaget initiativet Financial Equality. Det inleddes med filmen Why Women Don’t Own, som producerades i samarbete med tankesmedjan Ownershift och en grupp kvinnliga entreprenörer inom Företaget:s nätverk.",
					"lightbox_column2": "Utgångspunkten är att män och kvinnor är långt ifrån ekonomiskt jämställda, och syftet med initiativet är att belysa problematiken och peka på vad Företaget kan göra för att uppmuntra till ett mer jämställt sparande och ägande. Financial Equality uppmärksammades med Annas jämställdhets- och mångfaldspris för 2020.",
					"lightbox_img_caption": "Moa Gürbüzer, grundare av Oddbird, Polina Otto, grundare av Carrus Network och Alice Moradian, medgrundare av Earth Bite."
				}
			]
		}
	},
	"numberCounter_header": "2020 i siffror",
	"numberCounter_text": "Trots det utmanande ekonomiska läget visade sig Företaget:s affärsmodell vara motståndskraftig under året, med gott finansiellt resultat.",
	"numberCounter_splash": "BÄSTA<br/>FÖRETAGSBANK",
	"numberCounter_usp1": "Årets affärsbank 2020 (Finansbarometern)",
	"numberCounter_usp2": "Nordens ledande företagsbank 2020 (Prospera)",
	"numberCounter": [
		"9,7",
		"0,46",
		"4:10",
		"21,0",
		"2 591",
		"48"
	],
	"numberCounterText": [
		"Räntabilitet på eget kapital",
		"K/I-tal",
		"Utdelning per aktie, kr (förslag)",
		"Kärnprimärkapitalrelation, procent",
		"Kreditportfölj, miljarder kr",
		"Hållbarhetsrelaterade obligationer arrangerade under 2020, miljarder kr"
	],
	"TextVideo": {
		"header": "I bra och dåliga tider",
		"text": "Pandemin har fått stor påverkan på människors liv och hälsa, liksom på företagen och samhället i stort – kanske i större utsträckning än någon hade kunnat föreställa sig. Den här filmen är till alla som kämpar för att ta sig igenom dessa utmanande tider."
	},
	"bubbles_header": "55 miljarder kronor i skapat värde per år",
	"bubbles_text": "Så mycket värde skapade och distribuerade Företaget i snitt per år 2016-2020",
	"bubbles": [
		{
			"nav_text": "Kunder",
			"value": "15 mdr kr",
			"description": "Räntor erlagda till kunder och obligationsinnehavare",
			"scale": "1"
		},
		{
			"nav_text": "Medarbetare",
			"value": "12 mdr kr",
			"description": "Löner, pensioner och förmåner till medarbetare",
			"scale": "0.8"
		},
		{
			"nav_text": "Aktieägare",
			"value": "10 mdr kr",
			"description": "Utdelning till aktieägare",
			"scale": "0.666"
		},
		{
			"nav_text": "Samhället",
			"value": "9 mdr kr",
			"description": "Betalningar till leverantörer",
			"scale": "0.6"
		},
		{
			"nav_text": "",
			"value": "7 mdr kr",
			"description": "Skatter och sociala avgifter",
			"scale": "0.46"
		},
		{
			"nav_text": "",
			"value": "2 mdr kr",
			"description": "Myndighetsavgifter",
			"scale": "0.1333"
		}
	],
	"video_overlay_header": "Tack vare vår robusta finansiella ställning gick vi in i pandemin från en styrkeposition, så att vi kunde stötta människor och företag",
	"video_link1_text": "Ordförandeord",
	"video_link2_text": "VD-ord",
	"footer": {
		"copyright": "© Copyright Företaget AB 2021"
	},
	"expander1": "<h1>Header olle</h1>",
	"expander2": "<p>olle kor mop</p>",
	"expander3": "",
	"expander4": "",
	"read_more_expander": "Read more",
	"case": {
		"phenomenal": {
			"indicator": "<em>CASE_</em>MILTTON PHENOMENAL",
			"headline": "<h2>Phenomenal things happen when we reach out​</h2>",
			"text1": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque convallis a cras semper auctor neque vitae tempus. Blandit cursus risus at ultrices mi tempus imperdiet nulla. Sit amet porttitor eget dolor morbi. Faucibus nisl tincidunt eget nullam non nisi est sit amet.</p>",
			"text2": "<p>Neque convallis a cras semper auctor neque vitae tempus. Blandit cursus risus at ultrices mi tempus imperdiet nulla. Sit amet porttitor eget dolor morbi. Faucibus nisl tincidunt eget nullam non nisi est sit amet.</p>"
		},
		"futures": {
			"indicator": "",
			"headline": "",
			"text1": "",
			"text2": ""
		}
	}
}