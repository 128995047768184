<template>
	<section class="section_tlib">
		<div class="tlib" ref="target" :id="'tlib' + randNum">
			<picture class="parallax_container">
				<img :src="require(`@/assets/img/${background}`)" class="parallax_image" alt="" :id="'parallax_image' + randNum">
			</picture>
			
			<div class="container">
				<div class="content flex">
					<div class="flex-two-thirds flex-gutter-side">

						<h2 class="tlib_header" :class="'tlib_header' +  randNum">{{ $t(tlibHeader) }}</h2>
						<p class="tlib_text" :class="'tlib_text' +  randNum">{{ $t(tlibText) }}</p>
						
						<div v-if="showArrow">
							<div class="arrow tlib_arrow" :class="'tlib_arrow' +  randNum">
								<svg xmlns="http://www.w3.org/2000/svg" width="66" height="64.346" viewBox="0 0 66 64.346">
									<g id="Group_1213" data-name="Group 1213" transform="translate(4.765) rotate(90)"><g id="Group_1206" data-name="Group 1206" transform="translate(0 -61.235)"><g id="Group_1204" data-name="Group 1204" transform="translate(0 33)"><path id="Path_1441" data-name="Path 1441" d="M0,0H62.333" fill="none" stroke="#fff" stroke-width="1"></path></g><g id="Group_1205" data-name="Group 1205" transform="translate(32.471 0.274)"><path id="Path_1442" data-name="Path 1442" d="M0,4.725,31.364-28,0-60.725" transform="translate(0 60.725)" fill="none" stroke="#fff" stroke-width="1"></path></g></g></g>
								</svg>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>
	</section>
</template>

<script>
// Text Left Image Background = tlib
import { computed, ref, onMounted, onUnmounted } from 'vue'
import gsap from 'gsap'

export default {
	name:'TextImageBg',
	props: {
		tlibHeader: String,
		tlibText: String,
		background: String,
		showArrow: Boolean
	},
	setup(props){

		const randNum = '_' + Math.floor(Math.random() * 1000000)
		const background = props.background

		// INTERCEPT
		let onlyOnce = true;
		const target = ref();
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting == true){

					if(onlyOnce == true){
						onlyOnce = false;
						inView();
					}
				}
			},
			{ threshold: 0.7 }
		);

		// INTERCEPT ANIMATION
		const inView = () => {

			// header
			gsap.to('.tlib_header' + randNum, {
				duration: 1.5,
				opacity: 1,
				y: 0,
				ease: 'power2.out',
				delay: 0
			})
			// text
			gsap.to('.tlib_text' + randNum, {
				duration: 1.5,
				opacity: 1,
				y: 0,
				ease: 'power2.out',
				delay: 0.3
			})

			// arrow
			gsap.to('.tlib_arrow' + randNum, {
				duration: 1.5,
				opacity: 1,
				y: 0,
				ease: 'power2.out',
				delay: 0.9
			})

		}
		// END INTERCEPT

		onMounted(()=>{
			const body = document.getElementsByTagName("BODY")[0];		
			const comp = document.getElementById('tlib' + randNum);
			const offset = comp.getBoundingClientRect();
			const top = offset.top;
			const height = comp.offsetHeight;

			// SCROLL PARALLAX
			const handleScroll = (evt) => {
				const window_height = window.innerHeight
				const image = document.getElementById('parallax_image' + randNum);
				const comp = document.getElementById('tlib' + randNum);
				const comp_box = comp.getBoundingClientRect();
				const comp_height = comp.offsetHeight // 812
				const comp_top = comp_box.top;

				if(comp_top <= window_height){
					const value = -1 * (((250 / (window_height + comp_height)) * comp_top) + 125)
					image.style.top = value + 'px'
				}
			}
			// END SCROLL PARALLAX

			// onMounted INTERCEPT
			observer.observe(target.value);

			// init gsap
			gsap.set(['.tlib_header', '.tlib_text'], {opacity: 0, y: '+=40'})
			gsap.set('.tlib_arrow', {opacity: 0, y: '-=20'})

			document.addEventListener('scroll', handleScroll)
		})

		return {
			target,
			randNum
		}

	}
}
</script>