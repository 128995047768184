<template>
  <ul class="lang_switcher">
      <li v-for="locale in locales" :key="locale" @click="switchLocale(locale)">{{ locale }}</li>
  </ul>
</template>

<script>

export default {
  name: 'LocaleSwitcher',
  methods: {
    switchLocale(locale){
      if( this.$i18n.locale !== locale){
        this.$i18n.locale = locale
      }
    }
  },
  data(){
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    }
  }
}

</script>



<style >
  .lang_switcher{
    list-style: none;
  }
  .lang_switcher li{
    display:inline;
    padding:5px 10px;
    border: 1px solid #d1d1d1;
    margin:0 5px;
    border-radius:3px;
    cursor: pointer;
  }
</style>